import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import SidebarAdmin from "../common/sidebar";
import TopbarAdmin from "../common/topbar";
import '../../Common/Common.css';



const Transaction = (props) => {
    const [getMerchantList, setMerchantList] = useState([{}])
    const [getAcquirerlist, setAcquirerlist] = useState([{}])
    const [getTransactionlist, setTransactionlist] = useState([{}])
    const [getRow, setRow] = useState(10)
    const [getFilter, setFilter] = useState({})
    const [getViewpopup, setViewpopup] = useState("")
    const [getTransactionDetail, setTransactionDetail] = useState({})

    const cookie = new Cookies()


    const update_transaction = async () => {
        try {
            if (document.getElementById("update_rrn").value != "") {
                props.setLoading("loading")
                const resp = await fetch(`${process.env.REACT_APP_URL}/admin/update_transaction`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=UTF-8"
                    },
                    body: JSON.stringify({
                        token: cookie.get('user'),
                        rrn: document.getElementById("update_rrn").value,
                        txn_id: getTransactionDetail.order_id
                    })
                })
                if (resp.status == 200) {
                    const dataset = await resp.json()
                    if (dataset.status == true) {
                        props.setLoading("success")
                        setViewpopup("")
                    } else {
                        props.setLoading("fail")
                        setViewpopup("")
                    }
                } else {
                    props.setLoading("fail")
                    setViewpopup("")
                }
            } else {
                alert("Please enter RRN")
            }
        } catch (error) {
            console.log(error)
        }
    }

    const search_transaction = async () => {
        try {
            const ffilter = getFilter
            const clearfilter = Object.keys(getFilter)
            for (let i = 0; i < clearfilter.length; i++) {
                const ccfilter = Object.keys(getFilter[clearfilter[i]])
                for (let j = 0; j < ccfilter.length; j++) {
                    if (getFilter[clearfilter[i]][ccfilter[j]] === 0 || getFilter[clearfilter[i]][ccfilter[j]] === "")
                        delete ffilter[clearfilter[i]]
                }
            }
            setTransactionlist([])
            document.getElementById("loadingbarhere").style.display = "grid"
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/search_transaction`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    count: getRow,
                    filter: ffilter
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setTransactionlist(dataset.data.data)
                    document.getElementById("loadingbarhere").style.display = "none"
                }
            }
        } catch (error) {
            console.log(error)
            document.getElementById("loadingbarhere").style.display = "none"
        }
    }




    const get_transaction_list = async () => {
        try {
            // const ffilter = getFilter
            // const clearfilter = Object.keys(getFilter)
            // for(let i =0;i<clearfilter.length;i++){
            //     const ccfilter = Object.keys(getFilter[clearfilter[i]])
            //     for(let j=0;j<ccfilter.length;j++){
            //         if(getFilter[clearfilter[i]][ccfilter[j]] === 0 || getFilter[clearfilter[i]][ccfilter[j]] === "")
            //         delete ffilter[clearfilter[i]]
            //     }
            // }
            document.getElementById("loadingbarhere").style.display = "grid"
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_transaction_list`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    count: getRow
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setTransactionlist(dataset.data.data)
                    document.getElementById("loadingbarhere").style.display = "none"
                }
            }
        } catch (error) {
            console.log(error)
            document.getElementById("loadingbarhere").style.display = "none"
        }
    }

    const get_acquirer = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_acquirer`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user')
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setAcquirerlist(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const get_merchant_list = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_merchant`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user')
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setMerchantList(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        // props.check_cookie()
        get_transaction_list()
        get_merchant_list()
        get_acquirer()
    }, [])



    const PopupView = () => {
        if (getViewpopup) {
            return (
                <>
                    <div className="create_popup_background" onClick={(e) => { setViewpopup(""); }}>
                    </div>
                    <div className="create_popup_container min_width_900">
                        <div className="popup_title flex flex-center space-between">
                            <h2>Transaction Status Update</h2>
                            <p onClick={(e) => { setViewpopup(""); }}>Close</p>
                        </div>
                        <div className="flex flex-center space-between">

                            <table className="create_table mt20">
                                <tr>
                                    <td>Txn ID:</td>
                                    <td> {getTransactionDetail.order_id}</td>
                                </tr>
                                <tr>
                                    <td>Email:</td>
                                    <td>₹ {(getTransactionDetail.amount / 100).toLocaleString("en-IN")}</td>
                                </tr>
                                <tr>
                                    <td>Phone:</td>
                                    <td>{getMerchantList.map((ee, index) => {
                                        if (ee.id == getTransactionDetail.mch_id) {
                                            return ee.name
                                        }
                                    })}</td>

                                </tr>
                                <tr>
                                    <td>Qr:</td>
                                    <td>{getAcquirerlist.map((ee, index) => {
                                        if (ee.id == getTransactionDetail.acq_id) {
                                            return ee.name
                                        }
                                    })}</td>
                                </tr>
                                <tr>
                                    <td>RRN:</td>
                                    <td><input id="update_rrn" placeholder="Enter RRN here"></input></td>
                                </tr>
                            </table>



                        </div>
                        <div className="flex flex-center justify-center full-width">
                            <button onClick={(e) => { update_transaction() }}>SUCCESS</button>
                        </div>
                    </div>
                </>
            )
        }
    }


    return (
        <>
            <TopbarAdmin handleDash={props.handleDash} />
            <div className="page_wrapper mt15">
                <div className="content_wrapper">
                    <div className="flex flex-gap-extended options_table_container">
                        < PopupView />
                        <table className="full-width options_table">
                            <td>
                                <label>Merchant:</label>
                            </td>
                            <td>
                                <select name="merchant_id" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }}>
                                    <option>Select Merchant</option>
                                    {
                                        getMerchantList.map((e, index) => {
                                            return (
                                                <option value={e.id}>{e.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </td>
                            {/* <tr>
                                <td>
                                    <label>Amount:</label>
                                </td>
                                <td>
                                    <input name="amount" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: (e.target.value * 100) } }) }} placeholder="Enter Amount" />
                                </td>
                            </tr> */}
                            <tr>
                                <td>
                                    <label>Order ID:</label>
                                </td>
                                <td>
                                    <input name="order_id" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Order ID" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>From:</label>
                                </td>
                                <td>
                                    <input name="created_time" onChange={(e) => { var myDate = e.target.value; myDate = myDate.split("-"); console.log(myDate); var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]); setFilter({ ...getFilter, [e.target.name]: { ...getFilter[e.target.name], $gte: newDate.getTime() } }) }} type="Date" />
                                </td>
                            </tr>
                            <tr>
                                <td><label>To:</label></td>
                                <td>
                                    <input name="created_time" onChange={(e) => { var myDate = e.target.value; myDate = myDate.split("-"); console.log(myDate); var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]); setFilter({ ...getFilter, [e.target.name]: { ...getFilter[e.target.name], $lte: newDate.getTime() + 86399999 } }) }} type="Date" />
                                </td>

                            </tr>
                        </table>
                        <table className="full-width options_table">
                            <tr>
                                <td>
                                    <label>Acquirer:</label>
                                </td>
                                <td>
                                    <select name="acquirer_id" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }}>
                                        <option>Select Acquirer</option>
                                        {
                                            getAcquirerlist.map((e, index) => {
                                                return (
                                                    <option value={e.id}>{e.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>RRN:</label>
                                </td>
                                <td>
                                    <input name="rrn" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter RRN Number" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>VPA / UPI ID:</label>
                                </td>
                                <td>
                                    <input name="payer_vpa" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter UPI ID" />
                                </td>
                            </tr>
                            {/* <tr>
                                <td><label>Mobile Number</label></td>
                                <td><input name="payer_mobile" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Mobile Number" /></td>
                            </tr> */}
                            <tr>
                                <td>
                                    <label>Status</label>
                                </td>
                                <td>
                                    <select name="status" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }}>
                                        <option value="SUCCESS">Success</option>
                                        <option value="FAILED">Failure</option>
                                        <option value="PENDING">Pending</option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="flex flex-center justify-center full-width mt30">
                        <button onClick={(e) => { search_transaction() }}>Search</button>
                    </div>
                </div>
                <div className="content_wrapper mt15">
                    <div className="tran_table">
                        <div className="rowscount mb10 flex justify-end full-width">
                            <select onBlur={(e) => { get_transaction_list() }} onChange={(e) => { setRow(e.target.value); }}>
                                <option value={10}>10 Rows</option>
                                <option value={20}>20 Rows</option>
                                <option value={50}>50 Rows</option>
                                <option value={100}>100 Rows</option>
                            </select>
                        </div>

                        <table>
                            <tr>
                                <th>Merchant</th>
                                <th>Acquirer</th>
                                <th>UTR</th>
                                <th>Order ID</th>
                                <th>Transaction ID</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Date & Time</th>

                            </tr>
                            {
                                getTransactionlist.map((e, index) => {
                                    let txn_status;
                                    if (e.status === "PENDING") {
                                        txn_status = <div className="pending" name={e.order_id}>
                                            <p>Pending</p>
                                        </div>
                                    }
                                    if (e.status === "SUCCESS") {
                                        txn_status = <div className="success">
                                            <p>Success</p>
                                        </div>
                                    }
                                    if (e.status === "FAILED") {
                                        txn_status = <div className="failed">
                                            <p>Failed</p>
                                        </div>
                                    }
                                    const create = new Date(e.created_time).toLocaleString()
                                    const update = new Date(e.updated_time).toLocaleString()
                                    return (
                                        <tr>
                                            <td>{getMerchantList.map((ee, index) => {
                                                if (ee.id == e.mch_id) {
                                                    return ee.name
                                                }
                                            })}</td>
                                            <td>{getAcquirerlist.map((ee, index) => {
                                                if (ee.id == e.acq_id) {
                                                    return ee.name
                                                }
                                            })}</td>
                                            <td>{e.rrn}</td>
                                            <td className="min_width_180">{e.txn_id}</td>
                                            <td className="min_width_180" onClick={(f) => { setTransactionDetail(e); setViewpopup(e.order_id); }}>{e.order_id}</td>
                                            <td>₹ {(e.amount / 100).toLocaleString("en-IN")}</td>
                                            <td>
                                                {txn_status}
                                            </td>
                                            {/* <td></td> */}
                                            <td style={{ fontSize: "12px" }}>{create} <br /> {update}</td>

                                        </tr>
                                    )
                                })
                            }
                        </table>
                        <div id="loadingbarhere" class="loader" style={{ width: "100%", display: 'none' }}></div>
                    </div>
                </div>

            </div>
        </>
    )
}



export default Transaction;