import { useState } from "react"
import Cookies from 'universal-cookie'
import { useNavigate } from "react-router-dom";
import '../../Common/Common.css';
const { default: SidebarAdmin } = require("../common/sidebar")
const { default: TopbarAdmin } = require("../common/topbar")




const Account = (props) => {

    const [getPassword, setPassword] = useState({})
    const cookies = new Cookies()
    const navigate = useNavigate()

    const password_reset = async () => {
        try {
            props.setLoading("loading")
            if (getPassword.password === getPassword.cpassword) {
                const resp = await fetch(`${process.env.REACT_APP_URL}/admin/reset_password`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8'
                    },
                    body: JSON.stringify({
                        token: cookies.get('user'),
                        password: getPassword.password
                    })
                })
                const dataset = await resp.json()
                console.log(dataset)
                if (resp.status === 200) {
                    if (dataset.status === true) {
                        cookies.remove("user");
                        props.setLoading("success")
                        navigate('/admin')
                    }else{
                        props.setLoading("fail")
                    }
                }else{
                    props.setLoading("fail")
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <>
            <TopbarAdmin />
            <div className="page_wrapper">
                <div className="content_wrapper mt15">

                    <div className="flex flex-gap-extended options_table_container justify-center">
                        <table className="options_table account_page_table">
                            <tr>
                                <td>
                                    <label>Name:</label>
                                </td>
                                <td>
                                    <label>Admin</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Email Address:</label>
                                </td>
                                <td>
                                    <label>admin@admin.com</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Mobile Number:</label>
                                </td>
                                <td>
                                    <label>9999999999</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>New Password:</label>
                                </td>
                                <td>
                                    <input name="password" onChange={(e) => { setPassword({ ...getPassword, [e.target.name]: e.target.value }) }} placeholder="Enter New Password" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Confirm Password:</label>
                                </td>
                                <td>
                                    <input name="cpassword" onChange={(e) => { setPassword({ ...getPassword, [e.target.name]: e.target.value }) }} placeholder="Enter New Password" />
                                </td>
                            </tr>

                        </table>
                    </div>
                    <div className="flex flex-center justify-center full-width mt30">
                        <button onClick={(e) => { password_reset() }}>Change Password</button>
                    </div>
                </div>
            </div>
   
        </>
    )
}


export default Account