import Cookies from 'universal-cookie'
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import '../../Common/Common.css';
const { default: SidebarMerchant } = require("../common/sidebar")
const { default: TopbarMerchant } = require("../common/topbar")




const AccountMerchant = (props) => {
    const [getPassword, setPassword] = useState({})
    const [getAccount, setAccount] = useState({})
    const cookies = new Cookies()
    const navigate = useNavigate()


    const get_acocunt_info = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/account`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify({
                    token: cookies.get('merchant')
                })
            })
            const dataset = await resp.json()
            console.log(dataset)
            if (resp.status === 200) {
                if (dataset.status === true) {
                    setAccount(dataset.data.data[0])
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const password_reset = async () => {
        try {
            if (getPassword.password === getPassword.cpassword) {
                props.setLoading("loading")
                const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/reset_password`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json; charset=UTF-8'
                    },
                    body: JSON.stringify({
                        token: cookies.get('merchant'),
                        password: getPassword.password
                    })
                })
                const dataset = await resp.json()
                console.log(dataset)
                if (resp.status === 200) {
                    if (dataset.status === true) {
                        props.setLoading("success")
                        cookies.remove("merchant");
                        navigate('/merchant')
                    } else {
                        props.setLoading("fail")
                    }
                } else {
                    props.setLoading("fail")
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const change_webhook_merchant = async () => {
        try {
            props.setLoading("loading")
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/change_webhook`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify({
                    token: cookies.get('merchant'),
                    webhook: document.getElementById("webhookurl").value
                })
            })
            const dataset = await resp.json()
            if (resp.status === 200) {
                if (dataset.status === true) {
                    document.getElementById("webhookurl").value = ""
                    get_acocunt_info()
                    props.setLoading("success")
                } else {
                    props.setLoading("fail")
                }
            } else {
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
        }
    }



    useEffect(() => {
        // props.check_cookie()
        get_acocunt_info()
    }, [])

    return (
        <>
            <TopbarMerchant />
            <div className="page_wrapper">
                <div className="content_wrapper mt15">

                    <div className="options_table_container flex justify-center flex-column">
                        <table className='options_table account_page_table full-width'>
                            <tr>
                                <td>
                                    <label>Name:</label>
                                </td>
                                <td>
                                    <label>{getAccount.name}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Email Address:</label>
                                </td>
                                <td>
                                    <label>{getAccount.email}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Mobile Number:</label>
                                </td>
                                <td>
                                    <label>{getAccount.phone}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>New Password:</label>
                                </td>
                                <td>
                                    <input name="password" onChange={(e) => { setPassword({ ...getPassword, [e.target.name]: e.target.value }) }} placeholder="Enter New Password" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Confirm Password:</label>
                                </td>
                                <td>
                                    <input name="cpassword" onChange={(e) => { setPassword({ ...getPassword, [e.target.name]: e.target.value }) }} placeholder="Enter New Password" />
                                </td>
                            </tr>

                        </table>
                        <div className="flex flex-center justify-center full-width mt30 mb30">
                            <button onClick={(e) => { password_reset() }}>Change Password</button>
                        </div>
                        <table className='options_table account_page_table full-width '>
                            <tr>
                                <td>
                                    <label>MID:</label>
                                </td>
                                <td>
                                    <label>{getAccount.mid}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Request Encryption Key:</label>
                                </td>
                                <td>
                                    <label>{getAccount.req_key}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Request Salt Key:</label>
                                </td>
                                <td>
                                    <label>{getAccount.req_salt}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Response Encryption Key:</label>
                                </td>
                                <td>
                                    <label>{getAccount.res_key}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Response Salt Key:</label>
                                </td>
                                <td>
                                    <label>{getAccount.res_salt}</label>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Webhook URL:</label>
                                </td>
                                <td>
                                    <input id='webhookurl' placeholder={getAccount.webhook} />
                                </td>
                            </tr>

                        </table>
                        <div className="flex flex-center justify-center full-width mt30">
                            <button onClick={(e) => { change_webhook_merchant() }}>Update URL</button>
                        </div>

                    </div>

                </div>
            </div>
        </>
    )
}


export default AccountMerchant