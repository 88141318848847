import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import '../../Common/Common.css';
import { LuDownload } from "react-icons/lu";
const { default: SidebarMerchant } = require("../common/sidebar")
const { default: TopbarMerchant } = require("../common/topbar")




const ReportMerchant = (props) => {
    const [getFilter, setFilter] = useState({ updated_time: { $gte: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime(), $lte: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate()).getTime() + 86399999 } })
    const [getHeaderForm, setHeaderForm] = useState(JSON.stringify({}))
    const [getReportList, setReportList] = useState([{}])

    const cookie = new Cookies()


    const get_report_list = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/get_report_list`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('merchant')
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setReportList(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const download_report = async (report_id) => {
        try {
            document.getElementById("report_id").value = report_id
            document.getElementById("formpost").submit()
        } catch (error) {
            console.log(error)
        }
    }


    const create_report = async () => {
        try {
            const ffilter = getFilter
            const clearfilter = Object.keys(getFilter)
            for (let i = 0; i < clearfilter.length; i++) {
                const ccfilter = Object.keys(getFilter[clearfilter[i]])
                for (let j = 0; j < ccfilter.length; j++) {
                    if (getFilter[clearfilter[i]][ccfilter[j]] === 0 || getFilter[clearfilter[i]][ccfilter[j]] === "")
                        delete ffilter[clearfilter[i]]
                }
            }
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/get_report`, {
                method: 'POST',
                body: JSON.stringify({
                    token: cookie.get('merchant'),
                    filter: JSON.stringify(ffilter)
                }),
                headers: {
                    'Content-type': 'application/json; charset=UTF-8',
                }
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    get_report_list()
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const exportcsv = async () => {
        try {
            const ffilter = getFilter
            const clearfilter = Object.keys(getFilter)
            for (let i = 0; i < clearfilter.length; i++) {
                const ccfilter = Object.keys(getFilter[clearfilter[i]])
                for (let j = 0; j < ccfilter.length; j++) {
                    if (getFilter[clearfilter[i]][ccfilter[j]] === 0 || getFilter[clearfilter[i]][ccfilter[j]] === "")
                        delete ffilter[clearfilter[i]]
                }
            }
            setHeaderForm(JSON.stringify(ffilter))

            document.getElementById("formpost").submit()
        } catch (error) {
            console.log(error)
        }
    }

    const setData = async () => {
        try {
            const ffilter = getFilter
            const clearfilter = Object.keys(getFilter)
            for (let i = 0; i < clearfilter.length; i++) {
                const ccfilter = Object.keys(getFilter[clearfilter[i]])
                for (let j = 0; j < ccfilter.length; j++) {
                    if (getFilter[clearfilter[i]][ccfilter[j]] === 0 || getFilter[clearfilter[i]][ccfilter[j]] === "")
                        delete ffilter[clearfilter[i]]
                }
            }
            setHeaderForm(JSON.stringify(ffilter))

        } catch (error) {
            console.log(error)
        }
    }




    useEffect(() => {
        get_report_list()
    }, [""])

    useEffect(() => {
        setData()
    })



    return (
        <>
            <TopbarMerchant handleDash={props.handleDash} />
            <div className="page_wrapper mt15">
                <div className="content_wrapper">

                    <div className="flex flex-gap-extended options_table_container">
                        <table className="full-width options_table">

                            <tr>
                                <td>
                                    <label>Status</label>
                                </td>
                                <td>
                                    <select name="status" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }); setData() }}>
                                        <option value="">All</option>
                                        <option value="SUCCESS">Success</option>
                                        <option value="FAILED">Failure</option>
                                        <option value="PENDING">Pending</option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>From:</label>
                                </td>
                                <td>
                                    <input name="updated_time" onChange={(e) => { var myDate = e.target.value; myDate = myDate.split("-"); console.log(myDate); var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]); setFilter({ ...getFilter, [e.target.name]: { ...getFilter[e.target.name], $gte: newDate.getTime() } }); setData() }} type="Date" />
                                </td>
                            </tr>
                            <tr>
                                <td><label>To:</label></td>
                                <td>
                                    <input name="updated_time" onChange={(e) => { var myDate = e.target.value; myDate = myDate.split("-"); console.log(myDate); var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]); setFilter({ ...getFilter, [e.target.name]: { ...getFilter[e.target.name], $lte: newDate.getTime() + 86399999 } }); setData() }} type="Date" />
                                </td>

                            </tr>

                        </table>
                    </div>
                    <div className="flex flex-center justify-center full-width mt30">
                        <button onClick={(e) => { create_report(); }}>Download Report</button>
                    </div>
                    <div className="tran_table">
                        <div>
                            <table>
                                <tr>
                                    <th>Created Time</th>
                                    <th>Status</th>
                                    <th>Generated Time</th>
                                    <th>Download</th>
                                </tr>
                                {
                                    getReportList.map((e, index) => {
                                        console.log(e)
                                        const createtimeorg = new Date(e.created_time)
                                        const createtime = `${(createtimeorg.getDate() > 9) ? createtimeorg.getDate() : '0' + createtimeorg.getDate()}/${(createtimeorg.getMonth() > 8) ? createtimeorg.getMonth() + 1 : '0' + (createtimeorg.getMonth() + 1)}/${createtimeorg.getFullYear()} ${createtimeorg.getHours() > 9 ? createtimeorg.getHours() : '0' + createtimeorg.getHours()}:${createtimeorg.getMinutes() > 9 ? createtimeorg.getMinutes() : '0' + createtimeorg.getMinutes()}:${createtimeorg.getSeconds() > 9 ? createtimeorg.getSeconds() : '0' + createtimeorg.getSeconds()}`
                                        var updatetime = ""
                                        if (e.status != 'PENDING' && e.status != 'PROCESSING') {
                                            const updatetimeorg = new Date(e.updated_time)
                                            updatetime = `${(updatetimeorg.getDate() > 9) ? updatetimeorg.getDate() : '0' + updatetimeorg.getDate()}/${(updatetimeorg.getMonth() > 8) ? updatetimeorg.getMonth() + 1 : '0' + (updatetimeorg.getMonth() + 1)}/${updatetimeorg.getFullYear()} ${updatetimeorg.getHours() > 9 ? updatetimeorg.getHours() : '0' + updatetimeorg.getHours()}:${updatetimeorg.getMinutes() > 9 ? updatetimeorg.getMinutes() : '0' + updatetimeorg.getMinutes()}:${updatetimeorg.getSeconds() > 9 ? updatetimeorg.getSeconds() : '0' + updatetimeorg.getSeconds()}`
                                        } else {
                                        }

                                        return (
                                            <tr>
                                                <td>{createtime}</td>
                                                <td>{e.status}</td>
                                                <td>{updatetime}</td>
                                                {
                                                    (e.status != 'PENDING' && e.status != 'PROCESSING') ?
                                                        <td>
                                                            <button className='clearbtndisplay' onClick={(f) => { download_report(e.id) }} >
                                                                <LuDownload className='transferbtn' />
                                                            </button>
                                                        </td> : <></>
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </table>
                            <div style={{ display: "none" }} id='loadinganimation' class="loader-line"></div>
                        </div>
                    </div>
                    <form id="formpost" action={`${process.env.REACT_APP_URL}/merchant/download_report`} target="_blank" method="POST">
                        <input name="token" value={cookie.get('merchant')} ></input>
                        <input name="report_id" id='report_id'  ></input>
                        <input type="submit"></input>
                    </form>
                </div>
            
            </div>
        </>
    )
}

export default ReportMerchant