import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';
import '../../Common/Login.css';
import { FaEye } from "react-icons/fa";
import { FaEyeSlash } from "react-icons/fa";


const LoginMerchant = (props) => {
    const [getLogin, setLogin] = useState({})
    const navigate = useNavigate()
    const [password, setPassword] = useState(false);

    const login = async () => {
        try {
            document.getElementById("loginbutton").innerHTML = `<div id="loadinganimation" class="loader1" style="margin-left: 20px;"></div>`
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/login`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify(getLogin)
            })
            const dataset = await resp.json()
            console.log(dataset)
            if (resp.status === 200) {
                if (dataset.status === true) {
                    const cookies = new Cookies()
                    cookies.set('merchant', dataset.data.token, { path: '/' })
                    // cookies.set('merchant', dataset.data.token, { path: '/', secure: true, httpOnly: false, domain: `${process.env.REACT_APP_DOMAIN}`, sameSite: 'strict' })
                    props.verify_ui_merchant()
                    navigate('/merchant/dashboard')
                } else {
                    alert(dataset.err.msg)
                    document.getElementById("loginbutton").innerHTML = `Login`
                }
            } else {
                document.getElementById("loginbutton").innerHTML = `Login`
            }
        } catch (error) {
            console.log(error)
        }
    }


    return (
        <>
            <div className="login_wrapper flex flex-center justify-center">
                <div className="login_container p20" data-aos="zoom-in">
                    <h3 className="text-center">Login</h3>
                    <div className="row mt30">
                        <label>Email</label>
                        <input name="email" onChange={(e) => { setLogin({ ...getLogin, [e.target.name]: e.target.value }) }} />
                    </div>
                    <div className="row mt30">
                        <label>Password</label>
                        <input name="password" type={password ? "text" : "password"} onChange={(e) => { setLogin({ ...getLogin, [e.target.name]: e.target.value }) }} />
                        <span className="passwordtoggleicon" onClick={() => { setPassword(password => !password) }}>
                            {password ? <FaEye /> : <FaEyeSlash />}
                        </span>
                    </div>
                    <div className="row mt30 flex justify-center">
                        <button id="loginbutton" onClick={(e) => { login() }}>Login</button>
                    </div>
                </div>
            </div>
        </>
    )
}


export default LoginMerchant;