import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import '../../Common/Sidebar.css';
import { MdDashboardCustomize } from "react-icons/md";
import { GrTransaction } from "react-icons/gr";
import { IoMdList } from "react-icons/io";
import { MdOutlineFilterList } from "react-icons/md";
import { MdOutlinePlaylistAdd } from "react-icons/md";
import { HiClipboardList } from "react-icons/hi";
import { CgNotes } from "react-icons/cg";
import { BiAnalyse } from "react-icons/bi";
import { HiDocumentReport } from "react-icons/hi";
import { FaFileInvoice } from "react-icons/fa";
import { FaTheaterMasks } from "react-icons/fa";
import { BsFiletypeDoc } from "react-icons/bs";
import { TbArrowsJoin2 } from "react-icons/tb";
import { FaRegUser } from "react-icons/fa";
import { CiBank } from "react-icons/ci";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { CiLogout } from "react-icons/ci";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import { MdManageHistory } from "react-icons/md";

const SidebarAdmin = (props) => {
    const [submenu, setSubMenu] = useState(false)
    return (
        <>
            <div className="sidebar">
                <div className="logo">
                    <img src="https://www.vectorlogo.zone/logos/upi/upi-icon.svg" alt="upilogo" /><h2>  UPI Payin</h2>
                </div>
                <div className="sidebar_list">
                    <ul>
                        <li>
                            <NavLink to="/admin/dashboard">
                                <MdDashboardCustomize className="nav_icon" />
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/transaction">
                                <GrTransaction className="nav_icon" />
                                <span>Transaction</span>
                            </NavLink>
                        </li>
                        <li>
                            <p className={submenu ? 'active submenu_wrapper' : 'submenu_wrapper'} onClick={() => { setSubMenu(submenu => !submenu) }}>
                                <MdManageHistory className="nav_icon" />
                                <span>Logs Management</span>
                                <span>{submenu ? <FaCaretUp className="submenu_icon" /> : <FaCaretDown className="submenu_icon" />}</span>
                            </p>
                            <div className={submenu ? 'block submenu' : 'none submenu'}>
                                <ul className="submenu_list">
                                    <li>
                                        <NavLink to="/admin/utr">
                                            <IoMdList className="nav_icon" />
                                            <span>URT/RRN Logs</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/admin/transfer">
                                            <MdOutlineFilterList className="nav_icon" />
                                            <span>Unclaimed Logs</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/admin/log">
                                            <MdOutlinePlaylistAdd className="nav_icon" />
                                            <span>Create Logs</span>
                                        </NavLink>
                                    </li>
                                    <li>
                                        <NavLink to="/admin/uploadlog">
                                            <HiClipboardList className="nav_icon" />
                                            <span>Upload Logs</span>
                                        </NavLink>
                                    </li>
                                </ul>
                            </div>
                        </li>
                        <li>
                            <NavLink to="/admin/uploadstatement">
                                <CgNotes className="nav_icon" />
                                <span>Upload Statement</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/report">
                                <HiDocumentReport className="nav_icon" />
                                <span>Report</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/merchant">
                                <FaRegUser className="nav_icon" />
                                <span>Merchant</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/acquirer">
                                <CiBank className="nav_icon" />
                                <span>Acquirer</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/invoice">
                                <FaFileInvoice className="nav_icon" />
                                <span>Invoice</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/analyze">
                                <BiAnalyse className="nav_icon" />
                                <span>Analyzer</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/api">
                                <BsFiletypeDoc className="nav_icon" />
                                <span>API Document</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/integration">
                                <TbArrowsJoin2 className="nav_icon" />
                                <span>Integration</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/fraud">
                                <FaTheaterMasks className="nav_icon" />
                                <span>Fraud Prevention</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/account">
                                <MdOutlineAccountBalanceWallet className="nav_icon" />
                                <span>Account</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/admin/logout">
                                <CiLogout className="nav_icon" />
                                <span>Logout</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>

        </>
    )
}



export default SidebarAdmin
