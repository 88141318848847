import React, { Component } from 'react'
import Chart from 'react-google-charts'


class MultiLineChart extends Component {
    render() {
        return (
            <div className="container mt-5">
                <Chart
                    width={'100%'}
                    height={'40vh'}
                    chartType="LineChart"
                    loader={<div>Loading Chart</div>}
                    data={this.props.LineData}
                    options={this.props.LineChartOptions}
                    rootProps={{ 'data-testid': '2' }}
                />
            </div>
        )
    }
}






export default MultiLineChart