import { NavLink } from "react-router-dom";
import '../../Common/Sidebar.css';
import { MdDashboardCustomize } from "react-icons/md";
import { GrTransaction } from "react-icons/gr";
import { IoMdList } from "react-icons/io";
import { HiDocumentReport } from "react-icons/hi";
import { FaFileInvoice } from "react-icons/fa";
import { BsFiletypeDoc } from "react-icons/bs";
import { TbArrowsJoin2 } from "react-icons/tb";
import { MdOutlineAccountBalanceWallet } from "react-icons/md";
import { CiLogout } from "react-icons/ci";

const SidebarMerchant = (props) => {
    return (
        <>
            <div className="sidebar">
                <div className="logo">
                    <img src="https://www.vectorlogo.zone/logos/upi/upi-icon.svg" /><h2>  UPI Payin</h2>
                </div>
                <div className="sidebar_list">
                    <ul>
                        <li>
                            <NavLink to="/merchant/dashboard">
                                <MdDashboardCustomize className="nav_icon" />
                                <span>Dashboard</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/merchant/transaction">
                                <GrTransaction className="nav_icon" />
                                <span>Transaction</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/merchant/utr">
                                <IoMdList className="nav_icon" />
                                <span>UTR/RRN Logs</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/merchant/report">
                                <HiDocumentReport className="nav_icon" />
                                <span>Report</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/merchant/invoice">
                                <FaFileInvoice className="nav_icon" />
                                <span>Invoice</span>
                            </NavLink>
                        </li>
                        {/* <li>
                        <NavLink to="/merchant/fraud">Fraud Prevention</NavLink>
                    </li> */}
                        <li>
                            <NavLink to="/merchant/api">
                                <BsFiletypeDoc className="nav_icon" />
                                <span>API Document</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/merchant/integration">
                                <TbArrowsJoin2 className="nav_icon" />
                                <span>Integration</span>
                            </NavLink>
                        </li>
                        {/* <li>
                        <NavLink to="/merchant/merchant">Merchant</NavLink>
                    </li>
                    <li>
                        <NavLink to="/merchant/acquirer">Acquirer</NavLink>
                    </li> */}
                        <li>
                            <NavLink to="/merchant/account">
                                <MdOutlineAccountBalanceWallet className="nav_icon" />
                                <span>Account</span>
                            </NavLink>
                        </li>
                        <li>
                            <NavLink to="/merchant/logout">
                                <CiLogout className="nav_icon" />
                                <span>Logout</span>
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    )
}



export default SidebarMerchant
