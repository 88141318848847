import { useEffect, useState } from "react";
import Cookies from 'universal-cookie';
import '../../Common/Header.css';
import { FaUserCircle } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";


const TopbarMerchant = (props) => {
    const [getAccount, setAccount] = useState({})
    const cookies = new Cookies()

    // props.handleDash("welcome")
    const get_acocunt_info = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/account`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify({
                    token: cookies.get('merchant')
                })
            })
            const dataset = await resp.json()
            console.log(dataset)
            if (resp.status === 200) {
                if (dataset.status === true) {
                    setAccount(dataset.data.data[0])
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const Getsearch = () => {
        if (window.location.pathname === "/merchant/dashboard") {
            return (
                <div className="dashboard_header_filter mr20">
                    <button onClick={(e) => { props.setDashboard(true) }}>Filter</button>
                </div>
            )
        } else {
            return (
                <>
                    <div className="header_search_container flex flex-center mr20">
                        <span><IoIosSearch className="search_icon" /></span>
                        <input onClick={(e) => { }}></input>
                    </div>
                </>
            )
        }
    }

    useEffect(() => {
        // props.check_cookie()
        get_acocunt_info()
    }, [])

    return (
        <>
            <div className="header_wrapper flex full-width flex-center space-between">
                <div className="header_left">
                    <p>Welcome {getAccount.name}</p>
                </div>
                <div className="header_right flex flex-center">
                    <Getsearch />
                    {/* <span class="material-icons spanner">search</span>
                    <input onClick={(e) => { }}></input> */}
                    <div className="profile_container flex flex-center">
                        <div className="profile_name flex flex-center">
                            <p>{getAccount.name}</p>
                            <p className="ml10">Merchant</p>
                        </div>
                        <div className="profile_icon flex flex-center ml10">
                            <FaUserCircle className="user_icon" />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default TopbarMerchant