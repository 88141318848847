import React from "react";
import '../../Common/Header.css';
import { FaUserCircle } from "react-icons/fa";
import { IoIosSearch } from "react-icons/io";

const TopbarAdmin = (props) => {

    // props.handleDash("welcome")


    const Getsearch = () => {
        if (window.location.pathname === "/admin/dashboard") {
            return (
                <div className="dashboard_header_filter mr20">
                    <button onClick={(e) => { props.setDashboard(true) }}>Filter</button>
                </div>
            )
        } else {
            return (
                <>
                    <div className="header_search_container flex flex-center mr20">
                        <span><IoIosSearch className="search_icon" /></span>
                        <input onClick={(e) => { }}></input>
                    </div>
                </>
            )
        }
    }


    return (
        <>
            <div className="header_wrapper flex full-width flex-center space-between">
                <div className="header_left">
                    <p> Welcome Admin</p>
                </div>
                <div className="header_right flex flex-center">
                    <Getsearch />
                    {/* <span class="material-icons spanner">search</span>
                    <input onClick={(e) => { }}></input> */}
                    <div className="profile_container flex flex-center">
                        <div className="profile_name">
                            <p>Admin</p>
                        </div>
                        <div className="profile_icon flex flex-center ml10">
                            <FaUserCircle className="user_icon" />
                        </div>
                    </div>
                </div>

            </div>
        </>
    )
}

export default TopbarAdmin