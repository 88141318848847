import MultiLineChart from "../extra/chart"
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import '../../Common/Common.css';
import '../pages/Css/Dashboard.css';
import { MdCurrencyRupee } from "react-icons/md";
import { FaThumbsUp } from "react-icons/fa6";
import { BiMessageError } from "react-icons/bi";
import { MdOutlinePendingActions } from "react-icons/md";

import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import { Chart } from "react-google-charts";


const { default: SidebarAdmin } = require("../common/sidebar")
const { default: TopbarAdmin } = require("../common/topbar")




const Dashboard = (props) => {
    const dd = new Date;
    const [getTransactionlist, setTransactionlist] = useState([{}])
    const [getAcquirerlist, setAcquirerlist] = useState([{}])
    const [getMerchantList, setMerchantList] = useState([{}])
    const [getDashboardFilter, setDashboardFilter] = useState({ merchant_id: { $eq: "" }, acquirer_id: { $eq: "" }, created_time: { $gte: (dd.getTime() - ((dd.getTime() + 19800000) % 86400000)), $lte: (dd.getTime() - ((dd.getTime() + 19800000) % 86400000)) + 86400000 } })
    const [getDashboardData, setDashboardData] = useState({})
    const [getSuccessCount, setSuccessCount] = useState()
    const [getPendingCount, setPendingCount] = useState()
    const [getFailedCount, setFailedCount] = useState()
    const [getSuccessAmount, setSuccessAmount] = useState()
    const [getPendingAmount, setPendingAmount] = useState()
    const [getFailedAmount, setFailedAmount] = useState()
    const [getChartTick, setChartTick] = useState([])
    const [getChartData, setChartData] = useState([])


    const cookie = new Cookies()
    const get_transaction_list = async () => {
        try {
            props.setLoading("loading")
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_transaction_list`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    count: 10
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setTransactionlist(dataset.data.data)
                    props.setLoading("success")
                }else{
                    props.setLoading("fail")
                }
            }else{
                props.setLoading("fail")
            }
            
        } catch (error) {
            console.log(error)
        }
    }
    ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

    const data = {
        labels: [
            'Pending',
            'Success',
            'Failed',
        ],
        datasets: [
            {
                label: 'Transaction',
                data: [getPendingCount, getSuccessCount, getFailedCount],
                backgroundColor: [
                    '#ff933c',
                    '#1fc475',
                    '#f05c80',
                ],
                borderWidth: 0,
            },
        ],
    };

    function CApp() {
        return <PolarArea data={data} />;
    }


    var LineData = [["Date", "Success", "Failed", "Pending"]];

    const update_all_values = () => {
        setSuccessCount(0)
        setSuccessAmount(0)
        setPendingCount(0)
        setPendingAmount(0)
        setFailedCount(0)
        setFailedAmount(0)
        for (let i = 0; i < getDashboardData.length; i++) {
            if (getDashboardData[i]._id === "SUCCESS") {
                setSuccessCount(getDashboardData[i].number)
                setSuccessAmount(getDashboardData[i].total)
            }
            if (getDashboardData[i]._id === "PENDING") {
                setPendingCount(getDashboardData[i].number)
                setPendingAmount(getDashboardData[i].total)
            }
            if (getDashboardData[i]._id === "FAILED") {
                setFailedCount(getDashboardData[i].number)
                setFailedAmount(getDashboardData[i].total)
            }
        }
        let hi = []
        for (let i = 0; i < getChartTick.length; i++) {
            let hello = [`${new Date(getChartTick[i].time - (new Date(getChartTick[i].time)).getTimezoneOffset() * 60000).toISOString().slice(0, 10)}`, 0, 0, 0]
            for (let j = 0; j < getChartTick[i].chart_tick.length; j++) {
                const state = getChartTick[i].chart_tick[j]._id
                const total = getChartTick[i].chart_tick[j].total
                if (state === "SUCCESS") {
                    hello[1] = (total / 100)
                }
                if (state === "PENDING") {
                    hello[3] = (total / 100)
                }
                if (state === "FAILED") {
                    hello[2] = (total / 100)
                }
            }
            LineData[i + 1] = hello
            console.log(hello, i, "well")
        }
        setChartData(LineData)
        props.setLoading("success")
        console.log(LineData, "findal data")
    }


    // LineData = [
    //     ['Date', 'Success', 'Failed', 'Pending'],
    //     ['2023-10-10', 2300, 100, 0], 
    //     ['2023-10-11', 2300, 100, 800], 
    //     ['2023-10-13', 0, 0, 800]
    // ];


    const LineChartOptions = {
        backgroundColor: { fill: '#1c1751' },
        hAxis: {
            textStyle: { color: 'white' },
            baselineColor: 'transparent',
            titleTextStyle: { color: 'white' },
            title: 'Date',
            gridlines: {
                color: 'transparent'
            }
        },
        vAxis: {
            textStyle: { color: 'white' },

            baselineColor: 'transparent',
            titleTextStyle: { color: 'white' },
            title: 'INR',
            gridlines: {
                color: 'transparent'
            }
        },
        series: {
            0: { curveType: 'function', color: '#8760f9' },
            1: { curveType: 'function', color: '#1cde7b' },
            2: { curveType: 'function', color: '#fb9227' }
        },
        legend: { position: 'bottom', textStyle: { color: 'white' } },
    }

    var data2 = [
        ["Task", "Hours per Day"],
        ["Success", parseFloat((getSuccessAmount / 100).toFixed(2))],
        ["Failure", parseFloat((getFailedAmount / 100).toFixed(2))],
        ["Pending", parseFloat((getPendingAmount / 100).toFixed(2))],
    ];

    const options = {
        colors: ['#1dd07c', '#f05c80', '#825de9'],
        backgroundColor: { fill: '#1c1751' },
        titleTextStyle: { color: 'white' },
        pieHole: 0.4,
        is3D: false,
        legend: { position: 'top', textStyle: { color: 'white' } }
    };

    function Donut() {
        return (
            <Chart
                chartType="PieChart"
                width="100%"
                height="400px"
                data={data2}
                options={options}
            />
        );
    }

    const get_merchant_list = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_merchant`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user')
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setMerchantList(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const get_acquirer = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_acquirer`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user')
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setAcquirerlist(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const blururn = async () => {
        try {
            props.setLoading("loading")
            const dd = new Date()
            const temp = {}
            try {
                temp.merchant_id = { $eq: document.getElementById("merchant_iddash").value };
            } catch (error) {
                console.log(error)
            }
            try {
                temp.acquirer_id = { $eq: document.getElementById("acquirer_iddash").value }
            } catch (error) {
                console.log(error)
            }
            // try {
            //     var today = new Date();
            //     var myDate1 = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2); myDate1 = myDate1.split("-"); console.log(myDate1); var newDate1 = new Date(myDate1[0], myDate1[1] - 1, myDate1[2]);
            //     var myDate2 = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2); myDate2 = myDate2.split("-"); console.log(myDate2); var newDate2 = new Date(myDate2[0], myDate2[1] - 1, myDate2[2]);
            //     console.log(newDate1.getTime(),newDate2.getTime()+ 86399999 , "datedate")
            //     const tempp = {
            //         $gte: newDate1.getTime(),
            //         $lte: newDate2.getTime() + 86399999
            //     }
            //     temp.created_time = tempp
            // } catch (error) {
            //     console.log(error)
            //     const tempp = { $gte: (dd.getTime() - ((dd.getTime() + 19800000) % 86400000)), $lte: (dd.getTime() - ((dd.getTime() + 19800000) % 86400000)) + 86400000 - 19800000 }
            //     temp.created_time = tempp
            // }
            try {
                var myDate1 = document.getElementById("starttimedash").value; myDate1 = myDate1.split("-"); console.log(myDate1); var newDate1 = new Date(myDate1[0], myDate1[1] - 1, myDate1[2]);
                var myDate2 = document.getElementById("endtimedash").value; myDate2 = myDate2.split("-"); console.log(myDate2); var newDate2 = new Date(myDate2[0], myDate2[1] - 1, myDate2[2]);
                console.log(myDate1, myDate2, "datedate")
                const tempp = {
                    $gte: newDate1.getTime(),
                    $lte: newDate2.getTime() + 86399999
                }
                temp.created_time = tempp
            } catch (error) {
                console.log(error)
                const tempp = {
                    $gte: dd.getTime() - (dd.getTime() % 86400000) - 19800000,
                    $lte: dd.getTime() - (dd.getTime() % 86400000) + 19800000 + 86400000
                }
                temp.created_time = tempp
            }
            console.log(temp)
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/dashboard_value`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    filter: temp
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    console.log(dataset.data, "incoming data")
                    setDashboardData(dataset.data.data)
                    setChartTick(dataset.data.chart)
                    update_all_values()
                }else{
                    props.setLoading("fail")
                }
            }else{
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
        }
    }

    const dashboard_data = async () => {
        try {

            props.setLoading("loading")
            const ffilter = getDashboardFilter
            const clearfilter = Object.keys(getDashboardFilter)
            for (let i = 0; i < clearfilter.length; i++) {
                const ccfilter = Object.keys(getDashboardFilter[clearfilter[i]])
                for (let j = 0; j < ccfilter.length; j++) {
                    if (getDashboardFilter[clearfilter[i]][ccfilter[j]] === 0 || getDashboardFilter[clearfilter[i]][ccfilter[j]] === "") {
                        delete ffilter[clearfilter[i]]
                    }
                }
            }
            console.log(ffilter, "filter")




            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/dashboard_value`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    filter: ffilter
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    console.log(dataset.data, "incoming data")
                    setDashboardData(dataset.data.data)
                    setChartTick(dataset.data.chart)
                    update_all_values()
                }else{
                    props.setLoading("fail")
                }
            }else{
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
        }
    }




    useEffect(() => {
        // props.check_cookie()
        get_transaction_list()
        get_merchant_list()
        get_acquirer()
        dashboard_data()
    }, [])


    const [count, setCount] = useState(0);

    useEffect(() => {
        update_all_values()
    }, [getChartTick])



    const PopupView = () => {
        if (props.getDashboard) {
            return (
                <>
                    <div className="create_popup_background" onClick={(e) => { props.setDashboard(false) }}>
                    </div>
                    <div className="create_popup_container">
                        <div className="popup_title flex flex-center space-between">
                            <h2>Filter Data</h2>
                            <p onClick={(e) => { props.setDashboard(false) }}>Close</p>
                        </div>
                        <table className="create_table mt20">
                            <tr>
                                <td><label>Merchant : </label></td>
                                <td>
                                    <select name="merchant_id" id="merchant_iddash" >
                                        {/* {
                                                getMerchantList.map((e, index) => {
                                                    if (e.id == getDashboardFilter.merchant_id["$eq"]) {
                                                        return (
                                                            <option value={e.id}>{e.name}</option>
                                                        )
                                                    }
                                                })
                                            } */}
                                        <option value="">All Merchant</option>
                                        {
                                            getMerchantList.map((e, index) => {
                                                // if (e.id === getDashboardFilter.merchant_id.$eq) {

                                                // } else {
                                                return (
                                                    <option value={e.id}>{e.name}</option>
                                                )
                                                // }
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Acquirer</td>
                                <td>
                                    <select name="acquirer_id" id="acquirer_iddash" >
                                        {/* {
                                                getAcquirerlist.map((e, index) => {

                                                    if (e.id == getDashboardFilter.acquirer_id.$eq) {
                                                        return (
                                                            <option value={e.id}>{e.name}</option>
                                                        )
                                                    }
                                                })
                                            } */}
                                        <option value="">All Acquirers</option>
                                        {
                                            getAcquirerlist.map((e, index) => {
                                                // if (e.id === getDashboardFilter.acquirer_id.$eq) {

                                                // } else {
                                                return (
                                                    <option value={e.id}>{e.name}</option>
                                                )
                                                // }
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>

                            <tr>
                                <td>From: (required)</td>
                                <td>
                                    <input name="created_time" id="starttimedash" type="Date" />
                                </td>
                            </tr>
                            <tr>
                                <td>To: (required)</td>
                                <td>
                                    <input name="created_time" id="endtimedash" type="Date" />
                                </td>
                            </tr>
                        </table>
                        <div className="flex justify-center btn_container">
                            <button onClick={(e) => { blururn() }}>Apply Filter</button>
                        </div>
                    </div>
                </>
            )
        }
    }




    return (
        <>
            <TopbarAdmin setDashboard={props.setDashboard} />

            <div className="page_wrapper">
                <div className="dashboard_wrapper">
                    <PopupView />
                    <div className="dashboard_top flex flex-gap mt15 ">
                        <div className="card_item full-width flex">
                            <div className="leftpart full-width">
                                <span className="card_icon_container p12 icon1 flex flex-center justify-center">
                                    <MdCurrencyRupee className="dashboard_icon" />
                                </span>
                            </div>
                            <div className="rightpart">
                                 <p><span>₹</span>{((getSuccessAmount + getPendingAmount + getFailedAmount) / 100).toLocaleString("en-IN")}</p>
                                <span>{(getSuccessCount + getPendingCount + getFailedCount)}</span>
                                <br />
                                <span>{(getSuccessCount + getPendingCount + getFailedCount) / (getSuccessCount + getPendingCount + getFailedCount) * 100}%</span>
                            </div>
                        </div>
                        <div className="card_item full-width p20 flex">

                            <div className="leftpart full-width">
                                <span className="card_icon_container p12 icon2 flex flex-center justify-center">
                                    <FaThumbsUp className="dashboard_icon" />
                                </span>
                            </div>
                            <div className="rightpart">
                                <p><span>₹</span>{(getSuccessAmount / 100).toLocaleString("en-IN")}</p>
                                <span>{getSuccessCount}</span>
                                <br />
                                <span>{parseInt(getSuccessCount / (getSuccessCount + getPendingCount + getFailedCount) * 100)}%</span>
                            </div>
                            {/* success */}
                        </div>
                        <div className="card_item full-width p20 flex">
                            <div className="leftpart full-width">
                                <span className="card_icon_container p12 icon3 flex flex-center justify-center">
                                    <BiMessageError className="dashboard_icon" />
                                </span>
                            </div>
                            <div className="rightpart">
                                <p><span>₹</span>{(getFailedAmount / 100).toLocaleString("en-IN")}</p>
                                <span>{getFailedCount}</span>
                                <br />
                                <span>{parseInt(getFailedCount / (getSuccessCount + getPendingCount + getFailedCount) * 100)}%</span>
                            </div>
                        </div>
                        <div className="card_item full-width p20 flex">
                            <div className="leftpart full-width">
                                <span className="card_icon_container p12 icon4 flex flex-center justify-center">
                                    <MdOutlinePendingActions className="dashboard_icon" />
                                </span>
                            </div>
                            <div className="rightpart">
                                <p><span>₹</span>{(getPendingAmount / 100).toLocaleString("en-IN")}</p>
                                <span>{getPendingCount}</span>
                                <br />
                                <span>{parseInt(getPendingCount / (getSuccessCount + getPendingCount + getFailedCount) * 100)}%</span>
                            </div>
                        </div>
                    </div>
                    <div className="line_chart_wrapper full-width mt15">
                        <h3>Transaction Statics</h3>
                        <MultiLineChart LineData={getChartData} LineChartOptions={LineChartOptions} style={{ maxWidth: '100%' }} />

                        {/* <div className="tran_table">
                                <div>
                                    <h3>Recent Orders:</h3>
                                    <table>
                                        <tr>
                                            <th>Transaction ID</th>
                                            <th>Order ID</th>
                                            <th>Amount</th>
                                            <th className="specialCenter">Status</th>
                                            <th className="specialCenter">Updated Time</th>
                                        </tr>
                                        {
                                            getTransactionlist.map((e, index) => {
                                                let txn_status;
                                                if (e.status === "PENDING") {
                                                    txn_status = <div className="pending" name={e.order_id}>
                                                        <p>Pending</p>
                                                    </div>
                                                }
                                                if (e.status === "SUCCESS") {
                                                    txn_status = <div className="success">
                                                        <p>Success</p>
                                                    </div>
                                                }
                                                if (e.status === "FAILED") {
                                                    txn_status = <div className="failed">
                                                        <p>Failed</p>
                                                    </div>
                                                }
                                                const create = new Date(e.created_time).toLocaleString()
                                                const update = new Date(e.updated_time).toLocaleString()
                                                return (
                                                    <tr>
                                                        <td>{e.txn_id}</td>
                                                        <td>{e.order_id}</td>
                                                        <td>Rs {(e.amount / 100).toFixed(2)}</td>
                                                        <td>
                                                            {txn_status}
                                                        </td>
                                                        <td>{update}</td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </table>
                                </div>
                            </div> */}
                    </div>
                    <div className="flex mt15 flex-gap">
                        <div className="multi_data_chart_wrapper text-center">
                            <h3 className="mb20">Transaction Status</h3>
                            <div className="full-width flex flex-center justify-center">
                                <CApp />
                            </div>
                        </div>
                        <div className="donut_chart_wrapper text-center">
                            <h3>Amount Status</h3>
                            <div className="full-width flex flex-center justify-center">
                                <Donut />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <style>
                {
                    `
 
                    .color4 div{
                        height: 10px;
                        width: 10px;
                        background-color: white;
                    }
                    .child{
                        display: 100%,
                    }
                    .chart div, h3{
                        // padding: 1px;
                        display: block;
                        // background-color: #1c1751 !important;
                        border-radius: 10px;
                    }
                    .chart h3{
                        padding: 10px;
                    }
                    .chart p{
                        display: flex;
                    }
                    .chart1{
                        display: flex;
                        background-color: #1c1751 !important;
                        border-radius: 10px;
                        text-align:center;
                        margin: 10px;
                    }
                    .chart1 div , p{
                        background-color: #1c1751 !important;
                        border-radius: 10px;
                    }
                    .chart1 div canvas{
                        font-color: white;
                        background-color: #1c1751 !important;
                        position: sticky;
                        border-radius: 10px;
                    }
                    .chart2{
                        display: block;
                        text-align:center;
                        background-color: #1c1751 !important;
                        border-radius: 10px;
                        margin: 20px;
                        margin-left: 10px;
                    }
                    .chart2 h3{
                        padding: 10px;
                    }
                    .chart2 div{
                        margin-top: -5px;
                    }
                    .displayBlockup{
                        margin: 30px;
                        margin-top: 30%;
                    }
                    .color1{
                        display:flex;
                    }
                    .color2{
                        display:flex;
                    }
                    .color3{
                        display:flex;
                    }
                    .color1 div{
                        height: 10px;
                        width: 10px;
                        margin-top: 7px;
                        border: 1px solid black;
                        background-color: #ff933c !important;    
                    }
                    .color2 div{
                        height: 10px;
                        width: 10px;
                        margin-top: 7px;
                        border: 1px solid black;
                        background-color: #1fc475 !important;    
                    }
                    .color3 div{
                        height: 10px;
                        width: 10px;
                        margin-top: 7px;
                        border: 1px solid black;
                        background-color: #f05c80 !important;    
                    }
                    .flexallow div{
                        display: flex;
                    }
                    .flexography{
                        display: flex !important;
                    }
                    .tran_table div, table, tr, th,td{
                        padding: 3px;
                        background-color: #1c1751;
                    }
                    .tran_table div{
                        border-radius: 10px;
                    }
                    .tran_table{
                        overflow-y: scroll;
                        height: 40vh;
                        padding: 10px;
                        background-color: #1c1751;
                        text-align: left;
                        border-radius: 10px;
                        margin-top: 20px;
                    }
                    .tran_table::-webkit-scrollbar{
                        display: none;
                      }
                    .tran_table table{
                        width: 100%;   
                    }
                    .tran_table td,th{
                        padding-right: 20px;
                        padding-left: 20px;
                    }
                    .success p{
                        color: #21c97b;
                        background-color: #e4fcef;
                        background-color: #e4fcef !important;
                        padding: 5px;
                        text-align: center;
                    }
                    .pending p{
                        color: #ff8c33;
                        background-color: #fff3e7;
                        background-color: #fff3e7 !important;
                        padding: 5px;
                        text-align: center;
                    } 
                    .failed p{
                        color: #f05c80;
                        background-color: #f4d9de;
                        background-color: #f4d9de !important;
                        padding: 5px;
                        text-align: center;
                    }     
                    .specialCenter{
                        text-align: center;
                    }
                    

                    .date button{
                        color: white;
                        font-size: 14px;
                        border-radius: 8px;
                        border: 0px solid black;
                        padding: 10px;
                    }
                    .date button:hover{
                        border-radius: 100px;
                    }
                    .donutme{
                        border-radius: 10px;
                    }
                    .donutme svg{
                        height: 340px;
                        border-radius: 0px 0px 10px 10px;
                    }
                    `
                }
            </style> */}
        </>
    )
}

export default Dashboard