import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import '../../Common/Common.css'
const { default: SidebarAdmin } = require("../common/sidebar")
const { default: TopbarAdmin } = require("../common/topbar")



const Merchant = (props) => {
    const [getMerchant, setMerchant] = useState({})
    const [getMerchantList, setMerchantList] = useState([{}])
    const [getAcquirerlist, setAcquirerlist] = useState([{}])
    const [getMerchantdetail, setMerchantdetail] = useState([{}])
    const [getViewpopup, setViewpopup] = useState("")
    const [getRow, setRow] = useState(10)
    const [getFilter, setFilter] = useState({})
    const cookie = new Cookies()

    const create_merchant = async () => {
        try {
            props.setLoading("loading")
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/create_merchant`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    ...getMerchant
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    get_merchant_list()
                    props.setLoading("success")
                }else{
                    props.setLoading("fail")
                }
            }else{
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
        }
    }

    const get_merchant_list = async () => {
        try {
            setMerchantList([])
            document.getElementById("loadingbarhere").style.display = "grid"
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_merchant`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    count: getRow
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setMerchantList(dataset.data.data)
                    document.getElementById("loadingbarhere").style.display = "none"
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const search_merchant = async () => {
        try {
            const ffilter = getFilter
            const clearfilter = Object.keys(getFilter)
            for (let i = 0; i < clearfilter.length; i++) {
                const ccfilter = Object.keys(getFilter[clearfilter[i]])
                for (let j = 0; j < ccfilter.length; j++) {
                    if (getFilter[clearfilter[i]][ccfilter[j]] === 0 || getFilter[clearfilter[i]][ccfilter[j]] === "")
                        delete ffilter[clearfilter[i]]
                }
            }
            setMerchantList([])
            document.getElementById("loadingbarhere").style.display = "grid"
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/search_merchant`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    count: getRow,
                    filter: ffilter
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setMerchantList(dataset.data.data)
                    document.getElementById("loadingbarhere").style.display = "none"
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const get_acquirer = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_acquirer`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user')
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setAcquirerlist(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const get_merchant_details = async (merchant) => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_merchant_details`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    merchant: merchant
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setMerchantdetail(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }
    
    const modify_merchant = async (updateobj) => {
        try {
            props.setLoading("loading")
            console.log("hi")
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/modify_merchant`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    merchant: getViewpopup,
                    updates: updateobj
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    get_merchant_details(getViewpopup)
                    props.setLoading("success")
                }else{
                    props.setLoading("fail")
                }
            }else{
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
        }
    }

    const change_state_merchant = async (merchant, value) => {
        try {
            props.setLoading("loading")
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/modify_merchant`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    merchant: merchant,
                    updates: {
                        status: value
                    }
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    props.setLoading("success")
                    console.log("changed")
                }else{
                    props.setLoading("fail")
                }
            }else{
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
        }
    }

    const change_hosted_merchant = async (merchant, value) => {
        try {
            props.setLoading("loading")
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/modify_merchant`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    merchant: merchant,
                    updates: {
                        pg_hosted: value
                    }
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    props.setLoading("success")
                    console.log("changed")
                }else{
                    props.setLoading("fail")
                }
            }else{
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
        }
    }



    const password_reset_merchant = async () => {
        try {
            props.setLoading("loading")
            console.log("here")
            const password = document.getElementById("detailpassword").value
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/reset_password_merchant`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    merchant: getViewpopup,
                    password: password
                })
            })
            const dataset = await resp.json()
            console.log(dataset)
            if (resp.status === 200) {
                if (dataset.status === true) {
                    get_merchant_details(getViewpopup)
                    props.setLoading("success")
                }else{
                    props.setLoading("fail")
                }
            }else{
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
        }
    }

    const change_webhook = async (webhook) => {
        try {
            props.setLoading("loading")
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/change_webhook`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=UTF-8'
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    merchant: getViewpopup,
                    webhook: webhook
                })
            })
            const dataset = await resp.json()
            console.log(dataset)
            if (resp.status === 200) {
                if (dataset.status === true) {
                    get_merchant_details(getViewpopup)
                    props.setLoading("success")
                }else{
                    props.setLoading("fail")
                }
            }else{
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        // props.check_cookie()
        get_acquirer()
        get_merchant_list()
    }, [""])



    const PopupView = () => {
        if (getViewpopup) {
            return (
                <>
                    <div className="create_popup_background" onClick={(e) => { setViewpopup(""); get_merchant_list() }}>
                    </div>
                    <div className="create_popup_container min_width_900">
                        <div className="popup_title flex flex-center space-between">
                            <h2>Merchant Details</h2>
                            <p onClick={(e) => { setViewpopup(""); get_merchant_list() }}>Close</p>
                        </div>
                        <div className="flex flex-center space-between">
                            {
                                getMerchantdetail.map((e, index) => {
                                    return (

                                        <table className="create_table mt20">
                                            <tr>
                                                <td>Name:</td>
                                                <td>
                                                    <input value={e.name} disabled />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Email:</td>
                                                <td> <input value={e.email} disabled /></td>
                                            </tr>
                                            <tr>
                                                <td>Phone:</td>
                                                <td> <input value={e.phone} disabled /></td>
                                            </tr>
                                            <tr>
                                                <td>Qr:</td>
                                                <td> <select name="qr_acquirer" onBlur={(e) => { modify_merchant({ [e.target.name]: e.target.value }) }}>
                                                    {
                                                        getAcquirerlist.map((elem, index) => {
                                                            if (elem.id === e.qr_acquirer) {
                                                                return (
                                                                    <option value={elem.id}>{elem.name}</option>
                                                                )
                                                            }
                                                        })
                                                    }
                                                    {
                                                        getAcquirerlist.map((elem, index) => {
                                                            if (elem.id !== e.qr_acquirer) {
                                                                if (elem.type === "qr") {
                                                                    return (
                                                                        <option value={elem.id}>{elem.name}</option>
                                                                    )
                                                                }

                                                            }
                                                        })
                                                    }

                                                </select></td>
                                            </tr>
                                            <tr>
                                                <td>Collect:</td>
                                                <td> <select name="collect_acquirer" onBlur={(e) => { modify_merchant({ [e.target.name]: e.target.value }) }}>
                                                    {
                                                        getAcquirerlist.map((elem, index) => {
                                                            if (elem.id === e.collect_acquirer) {
                                                                return (
                                                                    <option value={elem.id}>{elem.name}</option>
                                                                )
                                                            } else {
                                                            }
                                                        })
                                                    }
                                                    {
                                                        getAcquirerlist.map((elem, index) => {
                                                            if (elem.id !== e.collect_acquirer) {
                                                                if (elem.type === "collect") {
                                                                    return (
                                                                        <option value={elem.id}>{elem.name}</option>
                                                                    )
                                                                }

                                                            }
                                                        })
                                                    }

                                                </select></td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Qr Status:
                                                </td>
                                                <td>
                                                    {
                                                        e.qr_acquirer_status ?
                                                            <select name="qr_acquirer_status" onBlur={(e) => { modify_merchant({ [e.target.name]: e.target.value }) }}>
                                                                <option value={true}>Active</option><option value={false}>InActive</option>
                                                            </select>
                                                            :
                                                            <select name="qr_acquirer_status" onBlur={(e) => { modify_merchant({ [e.target.name]: e.target.value }) }}>
                                                                <option value={false}>InActive</option><option value={true}>Active</option>
                                                            </select>
                                                    }
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Collect Status:
                                                </td>
                                                <td>
                                                    {
                                                        e.collect_acquirer_status ?
                                                            <select name="collect_acquirer_status" onBlur={(e) => { modify_merchant({ [e.target.name]: e.target.value }) }}>
                                                                <option value={true}>Active</option><option value={false}>InActive</option>
                                                            </select>
                                                            :
                                                            <select name="collect_acquirer_status" onBlur={(e) => { modify_merchant({ [e.target.name]: e.target.value }) }}>
                                                                <option value={false}>InActive</option><option value={true}>Active</option>
                                                            </select>
                                                    }
                                                </td>
                                            </tr>
                                        </table>
                                    )
                                })
                            }

                            {
                                getMerchantdetail.map((e, index) => {
                                    return (

                                        <table className="create_table mt20 ml50">
                                            <tr>
                                                <td>MID:</td>
                                                <td> <input value={e.mid} disabled /></td>
                                            </tr>
                                            <tr>
                                                <td>Request Encryption Salt:</td>
                                                <td> <input value={e.enc_salt} disabled /></td>
                                            </tr>
                                            <tr>
                                                <td>Request Encryption Key:</td>
                                                <td> <input value={e.enc_key} disabled /></td>
                                            </tr>
                                            <tr>
                                                <td>Response Encryption Salt:</td>
                                                <td> <input value={e.dec_salt} disabled /></td>
                                            </tr>
                                            <tr>
                                                <td>Response Encryption Key:</td>
                                                <td> <input value={e.dec_key} disabled /></td>
                                            </tr>
                                            <tr>
                                                <td>Webhook URL:</td>
                                                <td>
                                                    <input placeholder={e.webhook} onBlur={(e) => { change_webhook(e.target.value) }} />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>New Password:</td>
                                                <td>
                                                    <input name="password" id="detailpassword" />
                                                </td>
                                            </tr>
                                        </table>
                                    )
                                })
                            }

                            {
                                getMerchantdetail.map((e, index) => {
                                    return (
                                        <form id="formpost" style={{ display: "none" }} action={`${process.env.REACT_APP_URL}/api/initPayment`} target="_blank" method="POST">
                                            <input name="mid" value={e.mid} ></input>
                                            <input name="data" value={e.encryptedata} ></input>
                                            <input type="submit"></input>
                                        </form>
                                    )
                                })
                            }

                        </div>
                        <div className="flex flex-center justify-center full-width">
                            <button onClick={(e) => { document.getElementById("formpost").submit() }}>Test Pg Hosted</button>
                            <button onClick={(e) => { password_reset_merchant() }} className="ml30">Change Password</button>
                        </div>
                    </div>
                </>
            )
        }
    }


    return (
        <>
            <TopbarAdmin handleDash={props.handleDash} />
            <div className="page_wrapper mt15">
                <div className="content_wrapper">
                    <div className="flex flex-gap-extended options_table_container">
                        <PopupView />
                        <table className="options_table full-width">
                            <tr>
                                <td>
                                    <label>Name:</label>
                                </td>
                                <td>
                                    <input name="name" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value }); setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Name" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Email:</label>
                                </td>
                                <td>
                                    <input name="email" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value }); setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Email Address" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Phone:</label>
                                </td>
                                <td>
                                    <input name="phone" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value }); setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Mobile Number" />
                                </td>
                            </tr>
                        </table>
                        <table className="full-width options_table">
                            <tr>
                                <td>
                                    <label>Qr Route:</label>
                                </td>
                                <td>
                                    <select name="qr_acquirer" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value }); setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }}>
                                        <option>Select Acquirer</option>
                                        {
                                            getAcquirerlist.map((e, index) => {
                                                if (e.type === "qr") {
                                                    return (
                                                        <option value={e.id}>
                                                            {e.name}
                                                        </option>
                                                    )
                                                }
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Collect Route:</label>
                                </td>
                                <td>
                                    <select name="collect_acquirer" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value }); setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }}>
                                        <option>Select Acquirer</option>
                                        {
                                            getAcquirerlist.map((e, index) => {
                                                if (e.type === "collect") {
                                                    return (
                                                        <option value={e.id}>
                                                            {e.name}
                                                        </option>
                                                    )
                                                }
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Password:</label>
                                </td>
                                <td>
                                    <input name="password" onChange={(e) => { setMerchant({ ...getMerchant, [e.target.name]: e.target.value }) }} placeholder="Enter Password" />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="flex flex-center justify-center full-width mt30">
                        <button onClick={(e) => { create_merchant() }}>Create Merchant</button>
                        <button onClick={(e) => { search_merchant() }} className="ml30">Search Merchant</button>
                    </div>
                </div>
                <div className="content_wrapper mt15">
                    <div className="tran_table">
                        <div className="rowscount mb10 flex justify-end full-width">
                            <select onBlur={(e) => { setRow(e.target.value); get_merchant_list() }}>
                                <option value={10}>10 Rows</option>
                                <option value={20}>20 Rows</option>
                                <option value={50}>50 Rows</option>
                                <option value={100}>100 Rows</option>
                            </select>
                        </div>

                        <table>
                            <tr>
                                <th>Merchant Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Status</th>
                                <th>Pg Hosted</th>
                                <th>Qr</th>
                                <th>Collect</th>
                                <th>View</th>
                            </tr>
                            {
                                getMerchantList.map((e, index) => {
                                    return (
                                        <tr>
                                            <td>{e.name}</td>
                                            <td>{e.email}</td>
                                            <td>{e.phone}</td>
                                            <td>
                                                {
                                                    e.status ?
                                                        <select name={e.id} onBlur={(e) => { change_state_merchant(e.target.name, e.target.value) }} className="max-width_60">
                                                            <option value={true}>Active</option><option value={false}>InActive</option>
                                                        </select>
                                                        :
                                                        <select name={e.id} onBlur={(e) => { change_state_merchant(e.target.name, e.target.value) }} className="max-width_60">
                                                            <option value={false}>InActive</option><option value={true}>Active</option>
                                                        </select>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    (e.pg_hosted === "qr") ?
                                                        <select name={e.id} onBlur={(e) => { change_hosted_merchant(e.target.name, e.target.value) }} className="max-width_60">
                                                            <option value="qr">QR</option><option value="collect">Collect</option>
                                                        </select>
                                                        :
                                                        <select name={e.id} onBlur={(e) => { change_hosted_merchant(e.target.name, e.target.value) }} className="max-width_60">
                                                            <option value="collect">Collect</option><option value="qr">QR</option>
                                                        </select>
                                                }
                                            </td>
                                            {
                                                getAcquirerlist.map((ee, index) => {
                                                    if (e.qr_acquirer_status) {
                                                        if (e.qr_acquirer === ee.id) {
                                                            return <td style={{ color: "#20cc7a" }}>{ee.name}</td>
                                                        }
                                                    } else {
                                                        if (e.qr_acquirer === ee.id) {
                                                            return <td style={{ color: "#d9527d" }}>{ee.name}</td>
                                                        }
                                                    }
                                                })
                                            }
                                            {
                                                getAcquirerlist.map((ee, index) => {
                                                    if (e.collect_acquirer_status) {
                                                        if (e.collect_acquirer === ee.id) {
                                                            return <td style={{ color: "#20cc7a" }}>{ee.name}</td>
                                                        }
                                                    } else {
                                                        if (e.collect_acquirer === ee.id) {
                                                            return <td style={{ color: "#d9527d" }}>{ee.name}</td>
                                                        }
                                                    }
                                                })
                                            }
                                            <td>
                                                <button name={e.id} onClick={(e) => { setViewpopup(e.target.name); get_merchant_details(e.target.name); }} className="table_btn">View</button>
                                            </td>

                                        </tr>
                                    )
                                })
                            }
                        </table>
                        <div id="loadingbarhere" class="loader" style={{ width: "100%", display: 'none' }}></div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Merchant