import { Routes, Route } from "react-router-dom";
import './App.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Dashboard from "./admin_components/pages/dashboard";
import Transaction from "./admin_components/pages/transaction";
import Report from "./admin_components/pages/report";
import Invoice from "./admin_components/pages/invoice";
import Fraud from "./admin_components/pages/fraud";
import Apidoc from "./admin_components/pages/apidoc";
import Integration from "./admin_components/pages/integration";
import Account from "./admin_components/pages/account";
import Logout from "./admin_components/pages/logout";
import Merchant from "./admin_components/pages/merchant";
import Acquirer from "./admin_components/pages/acquirer";
import Login from "./admin_components/pages/login";
import DashboardMerchant from "./merchant_components/pages/dashboard";
import TransactionMerchant from "./merchant_components/pages/transaction";
import ReportMerchant from "./merchant_components/pages/report";
import InvoiceMerchant from "./merchant_components/pages/invoice";
import FraudMerchant from "./merchant_components/pages/fraud";
import ApidocMerchant from "./merchant_components/pages/apidoc";
import IntegrationMerchant from "./merchant_components/pages/integration";
import AccountMerchant from "./merchant_components/pages/account";
import LogoutMerchant from "./merchant_components/pages/logout";
import LoginMerchant from "./merchant_components/pages/login";
import { useState, useEffect } from "react";
import Utr from "./admin_components/pages/utr";
import Utrmerchant from "./merchant_components/pages/utr";
import Unclaimed from "./admin_components/pages/unclaimed";
import Log from "./admin_components/pages/log";
import Uploadlog from "./admin_components/pages/uploadlog";
import Analyzer from "./admin_components/pages/analyzer";
import Uploadstatement from "./admin_components/pages/uploadstatement";
import { Outlet } from "react-router-dom";
import Loader from './Common/Loader';
import ProgressBar from "./Common/ProgressBar";
import Cookies from "universal-cookie";
import SidebarAdmin from "./admin_components/common/sidebar";
import SidebarMerchant from "./merchant_components/common/sidebar";

function App() {
  const [getDashboard, setDashboard] = useState(false)
  useEffect(() => {
    AOS.init();
  }, [])

  const handleDash = (value) => {
    setDashboard(value)
  }
  // const cookie = new Cookies()

  // const check_cookie = async () => {
  //   try {
  //     const res = await fetch(`${process.env.REACT_APP_URL}/admin/cookie`, {
  //       method: 'POST',
  //       body: JSON.stringify({
  //         token: cookie.get('user')
  //       }),
  //       headers: {
  //         'Content-type': 'application/json; charset=UTF-8',
  //       }
  //     })
  //     const data = await res.json()
  //     if (res.status === 200) {
  //       if (data.status === true) {
  //         return
  //       } else {
  //         //login
  //       }
  //     } else {
  //       //login
  //     }
  //   } catch (error) {
  //     //login
  //   }
  // }
  const [getCsvFile, setCsvFile] = useState([]);
  const [getTransactionlist, setTransactionlist] = useState([{}])
  const [getMaxvalue, setMaxvalue] = useState(100)
  const [getCurrentvalue, setCurrentvalue] = useState(0)
  const cookie = new Cookies()


  const runwithme = async (getAcquirer, arr, i) => {
    try {
      const resp = await fetch(`${process.env.REACT_APP_URL}/admin/upload_log`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json; charset=UTF-8"
        },
        body: JSON.stringify({
          token: cookie.get('user'),
          acquirer: getAcquirer,
          data: arr
        })
      })
      if (resp.status === 200) {
        const dataset = await resp.json()
        if (dataset.status === true) {
          setCsvFile([])
          let arr1 = getTransactionlist
          arr1.push(...dataset.data.data)
          setTransactionlist(arr1)
          setCurrentvalue((arr1.length + 1))
        } else {
          alert("error occured")
        }
      } else {
        alert("error occured")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const upload_log = async (getAcquirer) => {
    try {
      if (getAcquirer) {
        setMaxvalue(getCsvFile.length)
        for (let i = 0; i < getCsvFile.length; i) {
          if (getCsvFile.length - i > 10) {
            let arr = []
            arr.push(getCsvFile[i])
            arr.push(getCsvFile[i + 1])
            arr.push(getCsvFile[i + 2])
            arr.push(getCsvFile[i + 3])
            arr.push(getCsvFile[i + 4])
            arr.push(getCsvFile[i + 5])
            arr.push(getCsvFile[i + 6])
            arr.push(getCsvFile[i + 7])
            arr.push(getCsvFile[i + 8])
            arr.push(getCsvFile[i + 9])
            i++
            i++
            i++
            i++
            i++
            i++
            i++
            i++
            i++
            i++
            runwithme(getAcquirer, arr, i)
          } else {
            i++
            runwithme(getAcquirer, [getCsvFile[i]], i)
          }
        }
      } else {
        alert("select acquirer")
      }

    } catch (error) {
      alert("error occured")
      console.log(error)
    }
  }

  const [getLoading, setLoading] = useState("");

  const PopupLoading = () => {
    if (getLoading !== "") {
      var class1;
      var class2;
      var labelval;
      var icon;
      if (getLoading === 'loading') {
        class1 = "loader-item loader-1"
        class2 = "material-icons loader-check-icon"
        labelval = "loading"
        setTimeout(clear_success_fail, 30000)
      }
      if (getLoading === 'success') {
        class1 = "loader-item loader-1 loader-success-completed"
        class2 = "material-icons loader-check-icon loader-check-icon-completed"
        labelval = "updated"
        icon = "check"
        setTimeout(clear_success_fail, 1000)
      }
      if (getLoading === 'fail') {
        class1 = "loader-item loader-1 loader-failure-completed"
        class2 = "material-icons loader-check-icon loader-failure-icon-completed"
        labelval = "failed"
        icon = "clear"
        setTimeout(clear_success_fail, 1000)
      }
      return (
        <>
          <div className="superparentPopup" onClick={(e) => { }}>
          </div>
          <div className="superchildPopup loadingpopup" style={{ width: "30%", height: "20%" }}>
            <div class="loader-wrapper">
              <div class={class1}>
                <i class={class2}>{icon}</i>
              </div>
              <div>
                <label>{labelval}</label>
              </div>
            </div>
          </div>
        </>
      )
    }
  }

  const clear_success_fail = async () => {
    try {
      if (getLoading == "success" || getLoading == "fail") {
        setLoading("")
      }
    } catch (error) {
      console.log(error)
    }
  }

  const [getAllowUiAdmin, setAllowUiAdmin] = useState(false)
  const [getAllowUiMerchant, setAllowUiMerchant] = useState(false)

  const verify_ui_admin = async () => {
    try {
      const resp = await fetch(`${process.env.REACT_APP_URL}/admin/verify`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify({
          token: cookie.get('user'),
        })
      })
      if (resp.status == 200) {
        const dataset = await resp.json()
        if (dataset.status == true) {
          setAllowUiAdmin(true)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const verify_ui_merchant = async () => {
    try {
      const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/verify`, {
        method: "POST",
        headers: {
          'Content-Type': 'application/json; charset=UTF-8'
        },
        body: JSON.stringify({
          token: cookie.get('merchant'),
        })
      })
      if (resp.status == 200) {
        const dataset = await resp.json()
        if (dataset.status == true) {
          setAllowUiMerchant(true)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    // props.check_cookie()
    verify_ui_admin()
    verify_ui_merchant()
  }, [""])


  return (
    <>
      <Routes>
        <Route path="loader" element={<Loader />} />
        <Route path="progress" element={<ProgressBar />} />
        <Route path="admin" element={<Login verify_ui_admin={verify_ui_admin} />} />
        {(getAllowUiAdmin) ?

          <Route path="admin" element={
            <>
              <div className="cover flex" >
                <SidebarAdmin />
                <section className="home-section">
                  <div className="rightdiv">
                    <PopupLoading />
                    <Outlet />
                  </div>
                </section>
              </div>

            </>
          }>
            <Route path="dashboard" element={<Dashboard setDashboard={setDashboard} getDashboard={getDashboard} setLoading={setLoading} />} />
            <Route path="transaction" element={<Transaction handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="utr" element={<Utr handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="transfer" element={<Unclaimed handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="log" element={<Log handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="uploadlog" element={<Uploadlog handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="uploadstatement" element={<Uploadstatement handleDash={handleDash} setLoading={setLoading} upload_log={upload_log} setCsvFile={setCsvFile} getCsvFile={getCsvFile} getTransactionlist={getTransactionlist} setTransactionlist={setTransactionlist} getMaxvalue={getMaxvalue} getCurrentvalue={getCurrentvalue} getRunning={(getCurrentvalue / getMaxvalue * 100).toFixed(2)} />} />
            <Route path="analyze" element={<Analyzer handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="report" element={<Report handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="invoice" element={<Invoice handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="fraud" element={<Fraud handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="api" element={<Apidoc handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="integration" element={<Integration handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="merchant" element={<Merchant handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="acquirer" element={<Acquirer handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="account" element={<Account handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="logout" element={<Logout handleDash={handleDash} setLoading={setLoading} />} />
            {/* <Route path="wallet" element={<Wallet check_cookie={check_cookie} />} />
          <Route path="merchant" element={<Merchant check_cookie={check_cookie} />} />
          <Route path="transaction" element={<Transaction check_cookie={check_cookie} />} />
          <Route path="report" element={<Report check_cookie={check_cookie} />} />
          <Route path="acquirer" element={<Acquirer check_cookie={check_cookie} />} />
          <Route path="blocking" element={<Blocking check_cookie={check_cookie} />} />
        <Route path="account" element={<Account check_cookie={check_cookie} />} /> */}
          </Route>
          : <></>
        }
        <Route path="merchant" element={<LoginMerchant verify_ui_merchant={verify_ui_merchant}/>} />
        {(getAllowUiMerchant) ?
          <Route path="merchant" element={
            <>
              <div className="cover flex" >
                <SidebarMerchant />
                <section className="home-section">
                  <div className="rightdiv">
                    <PopupLoading />
                    <Outlet />
                  </div>
                </section>
              </div>
            </>
          }>
            <Route path="dashboard" element={<><DashboardMerchant setDashboard={setDashboard} getDashboard={getDashboard} setLoading={setLoading} /></>} />
            <Route path="transaction" element={<TransactionMerchant handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="utr" element={<Utrmerchant handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="report" element={<ReportMerchant handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="invoice" element={<InvoiceMerchant handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="fraud" element={<FraudMerchant handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="api" element={<ApidocMerchant handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="integration" element={<IntegrationMerchant handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="account" element={<AccountMerchant handleDash={handleDash} setLoading={setLoading} />} />
            <Route path="logout" element={<LogoutMerchant handleDash={handleDash} setLoading={setLoading} />} />
            {/* <Route path="wallet" element={<Wallet check_cookie={check_cookie} />} />
          <Route path="merchant" element={<Merchant check_cookie={check_cookie} />} />
          <Route path="transaction" element={<Transaction check_cookie={check_cookie} />} />
          <Route path="report" element={<Report check_cookie={check_cookie} />} />
          <Route path="acquirer" element={<Acquirer check_cookie={check_cookie} />} />
          <Route path="blocking" element={<Blocking check_cookie={check_cookie} />} />
          <Route path="account" element={<AccountMerchant check_cookie={check_cookie} />} /> */}
          </Route> : <></>
        }
      </Routes>
    </>
  );
}



export default App;