import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import '../../Common/Common.css';
import SidebarAdmin from "../common/sidebar";
import TopbarAdmin from "../common/topbar";



const Log = (props) => {
    const [getMerchantList, setMerchantList] = useState([{}])
    const [getAcquirerlist, setAcquirerlist] = useState([{}])
    const [getData, setData] = useState({ vpa: "upi@upi", name: "upi" })

    const cookie = new Cookies()


    const create_log = async () => {
        try {
            props.setLoading("loading")
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/create_log`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    ...getData
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    console.log(document.getElementsByName("amount")[0].value)
                    document.getElementsByName("amount")[0].value = ""
                    document.getElementsByName("rrn")[0].value = ""
                    document.getElementsByName("name")[0].value = ""
                    document.getElementsByName("vpa")[0].value = ""
                    props.setLoading("success")
                }else{
                    props.setLoading("fail")
                }
            }else{
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
        }
    }




    const get_acquirer = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_acquirer`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user')
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setAcquirerlist(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const get_merchant_list = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_merchant`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user')
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setMerchantList(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        // props.check_cookie()
        get_merchant_list()
        get_acquirer()
    }, [])



    return (
        <>
            <TopbarAdmin handleDash={props.handleDash} />
            <div className="page_wrapper mt15">
                <div className="content_wrapper">

                    <div className="flex flex-gap-extended options_table_container">
                        <table className="full-width options_table">
                            <td>
                                <label>Merchant:</label>
                            </td>
                            <td>
                                <select name="merchant_id" onChange={(e) => { setData({ ...getData, [e.target.name]: e.target.value }) }}>
                                    <option>Select Merchant</option>
                                    {
                                        getMerchantList.map((e, index) => {
                                            return (
                                                <option value={e.id}>{e.name}</option>
                                            )
                                        })
                                    }
                                </select>
                            </td>
                            <tr>
                                <td>
                                    <label>Amount:</label>
                                </td>
                                <td>
                                    <input name="amount" onChange={(e) => { setData({ ...getData, [e.target.name]: (e.target.value * 100) }) }} placeholder="Enter Amount" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Name:</label>
                                </td>
                                <td>
                                    <input name="name" onChange={(e) => { setData({ ...getData, [e.target.name]: e.target.value }) }} placeholder="Enter Order ID" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Date:</label>
                                </td>
                                <td>
                                    <input name="date" onChange={(e) => { setData({ ...getData, [e.target.name]: e.target.value }) }} placeholder="DD/MM/YYYY" type="date" />
                                </td>
                            </tr>
                        </table>
                        <table className="full-width options_table">
                            <tr>
                                <td>
                                    <label>Acquirer:</label>
                                </td>
                                <td>
                                    <select name="acquirer_id" onChange={(e) => { setData({ ...getData, [e.target.name]: e.target.value }) }}>
                                        <option>Select Acquirer</option>
                                        {
                                            getAcquirerlist.map((e, index) => {
                                                return (
                                                    <option value={e.id}>{e.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>RRN:</label>
                                </td>
                                <td>
                                    <input name="rrn" onChange={(e) => { setData({ ...getData, [e.target.name]: e.target.value }) }} placeholder="Enter RRN Number" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>VPA / UPI ID:</label>
                                </td>
                                <td>
                                    <input name="vpa" onChange={(e) => { setData({ ...getData, [e.target.name]: e.target.value }) }} placeholder="Enter UPI ID" />
                                </td>
                            </tr>
                            <tr>
                                <td><label>Time:</label></td>
                                <td><input name="time" onChange={(e) => { setData({ ...getData, [e.target.name]: e.target.value }) }} placeholder="HH:MM" type="time" /></td>
                            </tr>
                        </table>
                    </div>
                    <div className="flex flex-center justify-center full-width mt30">
                        <button onClick={(e) => { create_log() }}>Create Log</button>
                    </div>
                </div>
            </div>

        </>
    )
}



export default Log;