import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import '../../Common/Common.css';
import SidebarAdmin from "../common/sidebar";
import TopbarAdmin from "../common/topbar";
import * as XLSX from "xlsx";


const Uploadstatement = (props) => {
    const [getAcquirerlist, setAcquirerlist] = useState([{}])
    const [getAcquirer, setAcquirer] = useState("")

    const cookie = new Cookies()





    const handlecsvRender = async (file) => {
        const d = new Date()
        const reader = new FileReader();
        const rABS = !!reader.readAsBinaryString;
        reader.onload = e => {
            /* Parse data */
            const bstr = e.target.result;
            const wb = XLSX.read(bstr, { type: rABS ? "binary" : "array" });
            /* Get first worksheet */
            const wsname = wb.SheetNames[0];
            const ws = wb.Sheets[wsname];
            /* Convert array of arrays */
            const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
            /* Update state */
            let arr = []
            for (let i = 1; i < data.length; i++) {
                console.log(data)
                if (data[i][0] === undefined || data[i][0] === "" || i < 13) {
                    continue;
                }
                try {
                    if (data[i][2].substring(0, 4) == "UPI/" && data[i][5] != "                    ") {
                        var temp = {
                            rrn: data[i][2].substring(4, 16),
                            name: "upi",
                            amount: data[i][5] * 100,
                            vpa: "upi@upi",
                            date: data[i][1].split("-")[2].trim() + "-" + data[i][1].split("-")[1] + "-" + data[i][1].split("-")[0] || d.toJSON().split("T")[0],
                            time: '01:01'
                        }
                        console.log(temp)
                        arr.push(temp)
                    }
                } catch (error) {
                    console.log(error, i, data[i])
                    continue;
                }
            }
            props.setCsvFile(arr)
            props.setTransactionlist([])
            // this.setState({ data: data, cols: make_cols(ws["!ref"]) });
        };
        if (rABS) reader.readAsBinaryString(file);
        else reader.readAsArrayBuffer(file);
    }

    const get_acquirer = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_acquirer`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user')
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setAcquirerlist(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    // const upload_log = async () => {
    //     try {
    //         if (getAcquirer) {
    //             alert("this might take some time to upload , press ok to continue")
    //             const resp = await fetch(`${process.env.REACT_APP_URL}/admin/upload_log`, {
    //                 method: "POST",
    //                 headers: {
    //                     "Content-Type": "application/json; charset=UTF-8"
    //                 },
    //                 body: JSON.stringify({
    //                     token: cookie.get('user'),
    //                     acquirer: getAcquirer,
    //                     data: getCsvFile
    //                 })
    //             })
    //             if (resp.status === 200) {
    //                 const dataset = await resp.json()
    //                 if (dataset.status === true) {
    //                     setCsvFile([])
    //                     setTransactionlist(dataset.data.data)
    //                 } else {
    //                     alert("error occured")
    //                 }
    //             } else {
    //                 alert("error occured")
    //             }
    //         } else {
    //             alert("please select acquirer")
    //         }
    //     } catch (error) {
    //         alert("error occured")
    //         console.log(error)
    //     }
    // }



    useEffect(() => {
        // props.check_cookie()
        get_acquirer()
    }, [])



    return (
        <>
            <TopbarAdmin handleDash={props.handleDash} />
            <div className="page_wrapper mt15">
                <div className="content_wrapper">
                    <div className="flex flex-gap-extended options_table_container justify-center">
                        <table className="options_table">
                            <tr>
                                <td>
                                    <label>Select File:</label>
                                </td>
                                <td>
                                    <input type="file" onChange={(e) => { handlecsvRender(e.target.files[0]) }} />
                                </td>
                            </tr>
                        </table>
                        <table className="options_table">
                            <tr>
                                <td>
                                    <label>Acquirer:</label>
                                </td>
                                <td>
                                    <select name="acquirer_id" onChange={(e) => { setAcquirer(e.target.value); }}>
                                        <option>Select Acquirer</option>
                                        {
                                            getAcquirerlist.map((e, index) => {
                                                return (
                                                    <option value={e.id}>{e.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="flex flex-center justify-center full-width mt30">
                        <button onClick={(e) => { props.upload_log(getAcquirer) }}>Submit file</button>
                    </div>
                    <div className="flex flex-center justify-center full-width mt50 mb30">
                    <progress id="file" max={props.getMaxvalue} value={props.getCurrentvalue}>hello</progress>
                    <label className="ml20">{props.getRunning}%</label>
                    </div>
                    {/* {(props.getCurrentvalue % 2 == 0) ? <label style={{ color: "green" }}>{props.getRunning}%</label> : <label style={{ color: "red" }}>{props.getRunning}%</label>} */}
                </div>
                <div className="content_wrapper mt15">
                    <div className="tran_table">

                        <table>
                            <tr>
                                <th>UTR/RRN</th>
                                <th>Amount</th>
                                <th>VPA</th>
                                <th>Date</th>
                            </tr>
                            {
                                props.getCsvFile.map((e, index) => {

                                    return (
                                        <tr >
                                            <td >{e.rrn}</td>
                                            <td>₹ {(e.amount / 100).toLocaleString("en-IN")}</td>
                                            <td>{e.vpa}</td>
                                            <td>{e.date}</td>

                                        </tr>
                                    )
                                })
                            }
                            {
                                props.getTransactionlist.map((e, index) => {
                                    var vvstyle = {
                                        color: 'white'
                                    }
                                    if (e.copy === true) {
                                        vvstyle = {
                                            color: 'red'
                                        }
                                    } else if (e.copy === false) {
                                        vvstyle = {
                                            color: 'green'
                                        }
                                    }
                                    return (
                                        <tr style={vvstyle}>
                                            <td >{e.rrn}</td>
                                            <td>₹ {e.amount}</td>
                                            <td>{e.vpa}</td>
                                            <td>{e.date}</td>

                                        </tr>
                                    )
                                })
                            }
                        </table>

                    </div>
                </div>
            </div>
        </>
    )
}



export default Uploadstatement;