import Cookies from 'universal-cookie'
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";


const { default: SidebarMerchant } = require("../common/sidebar")
const { default: TopbarMerchant } = require("../common/topbar")




const LogoutMerchant = (props) => {


    const cookies = new Cookies()
    const navigate = useNavigate()


    const logout = async () => {
        try {
            cookies.remove("merchant");
            navigate('/merchant')
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        logout()
    }, [])

    return (
        <>
            <TopbarMerchant handleDash={props.handleDash}/>
        </>
    )
}


export default LogoutMerchant