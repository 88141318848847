import { useEffect, useState } from "react";
import Cookies from "universal-cookie";
import '../../Common/Common.css';
const { default: SidebarAdmin } = require("../common/sidebar")
const { default: TopbarAdmin } = require("../common/topbar")




const Invoice = (props) => {
    const [getMerchantList, setMerchantList] = useState([{}])
    const [getGenqr, setGenqr] = useState({})
    const [getQrlist, setQrlist] = useState([])


    const cookie = new Cookies()



    const get_merchant_list = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_merchant`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user')
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setMerchantList(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const get_qr_code = async () => {
        try {
            props.setLoading("loading")
            let arr = []
            for (let i = 0; i < getQrlist.length; i++) {
                arr.push(getQrlist[i])
            }
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/create_qr_code`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    phone: 9999999999,
                    email: "admin@admin.com",
                    ...getGenqr
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    arr.push(dataset.data)
                    setQrlist(arr)
                    props.setLoading("success")
                }else{
                    props.setLoading("fail")
                }
            }else{
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
        }
    }

    const transaction_status = async () => {
        try {
            if (getQrlist.length > 0) {
                const resp = await fetch(`${process.env.REACT_APP_URL}/admin/check_status`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json; charset=UTF-8"
                    },
                    body: JSON.stringify({
                        token: cookie.get('user'),
                        data: getQrlist
                    })
                })
                const dataset = await resp.json()
                if (resp.status === 200) {
                    if (dataset.status === true) {
                        console.log(typeof (dataset.data.data), "welcome data")
                        console.log(dataset.data.data, "incoming")
                        setQrlist(dataset.data.data)
                    }
                }
            }
        } catch (error) {
            console.log(error)
        }
    }





    useEffect(() => {
        // props.check_cookie()
        get_merchant_list()
    }, [])

    return (
        <>
            <TopbarAdmin handleDash={props.handleDash} />
            <div className="page_wrapper mt15">
                <div className="content_wrapper">

                    <div className="flex flex-gap-extra-extended options_table_container justify-center">
                        <table className="options_table">
                            <tr>
                                <td>
                                    <label>Merchant:</label>
                                </td>
                                <td>
                                    <select name="merchant" onChange={(e) => { setGenqr({ ...getGenqr, [e.target.name]: e.target.value }) }}>
                                        <option>Select Merchant</option>
                                        {
                                            getMerchantList.map((e, index) => {
                                                return (
                                                    <option value={e.id}>{e.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                        </table>
                        <table className="options_table">
                            <tr>
                                <td>
                                    <label>Amount:</label>
                                </td>
                                <td>
                                    <input name="amount" onChange={(e) => { setGenqr({ ...getGenqr, [e.target.name]: (e.target.value * 100) }) }} placeholder="Enter Amount" />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="flex flex-center justify-center full-width mt30">
                        <button onClick={(e) => { get_qr_code() }}>Generate QR Code</button>
                    </div>
                    <div className="flex flex-center justify-center full-width mt30">
                        <button onClick={(e) => { transaction_status() }}>Check Invoice Status</button>
                    </div>
                </div>
                <div className="content_wrapper mt15">
                    <div className="tran_table">
                        <table>
                            <tr>
                                <th>QR Code</th>
                                <th>Transaction ID</th>
                                <th>Status</th>
                            </tr>
                            {
                                getQrlist.map((e, index) => {
                                    let txn_status;
                                    if (e.status === "PENDING") {
                                        txn_status = <div className="pending" name={e.order_id}>
                                            <div><p>Pending</p></div>
                                        </div>
                                    }
                                    if (e.status === "SUCCESS") {
                                        txn_status = <div className="success">
                                            <div><p>Success</p></div>
                                        </div>
                                    }
                                    if (e.status === "FAILURE") {
                                        txn_status = <div className="failed">
                                            <div><p>Failed</p></div>
                                        </div>
                                    }

                                    return (
                                        <tr>
                                            <td>
                                                <img src={e.qr} />
                                            </td>
                                            <td>{e.order_id}</td>
                                            <td>
                                                {txn_status}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </table>
                    </div>
                </div>

            </div>
        </>
    )
}

export default Invoice