const { default: SidebarMerchant } = require("../common/sidebar")
const { default: TopbarMerchant } = require("../common/topbar")


const IntegrationMerchant = (props) => {
    return (
        <>
            <TopbarMerchant handleDash={props.handleDash}/>
            <div className="parentTransaction">
                <div className="childTransaction">
                    Page is Under Development
                </div>
            </div>
            <style>
                {
                    `
                .parentTransaction{
                    color: white;
                    margin-top: 85px;
                }
                .childTransaction{
                    display:flex;
                    border-radius: 10px;
                    // background: #1c1751;
                }
                `
                }
            </style>
        </>
    )
}


export default IntegrationMerchant