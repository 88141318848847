import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import '../pages/Css/Dashboard.css'
import SidebarAdmin from "../common/sidebar";
import TopbarAdmin from "../common/topbar";
import { Chart } from "react-google-charts";
import '../../Common/Common.css';



const Analyzer = (props) => {
    const [getsData, setsData] = useState([])
    const [getfData, setfData] = useState([])
    const [getVolumeData, setVolumeData] = useState([])
    const [getTreeData, setTreeData] = useState([])

    const cookie = new Cookies()


    const arrange_data = async (dataset) => {
        try {
            const set1sdf = dataset.sf
            const set2sdf = dataset.tree
            let arrall = []
            let arrerr = []
            let arrapp = []
            let arr = [["Merchant", "Number"]]
            let arr1 = [["Merchant", "Number"]]
            let arr2 = [["Merchant", "Amount"]]
            for (let i = 0; i < set1sdf.length; i++) {
                if (set1sdf[i]._id.status === "SUCCESS") {
                    if (arrall.indexOf(set1sdf[i]._id.merchant_name) < 0) {
                        arrall.push(set1sdf[i]._id.merchant_name)
                    }
                } else if (set1sdf[i]._id.status === "FAILED") {
                    if (arrall.indexOf(set1sdf[i]._id.merchant_name) < 0) {
                        arrall.push(set1sdf[i]._id.merchant_name)
                    }
                }
            }
            for (let i = 0; i < set2sdf.length; i++) {
                if (arrerr.indexOf(set2sdf[i]._id.npci_error_code) < 0) {
                    arrerr.push(set2sdf[i]._id.npci_error_code)
                }
                if (arrapp.indexOf(set2sdf[i]._id.ref_url) < 0) {
                    arrapp.push(set2sdf[i]._id.ref_url)
                }
            }
            for (let i = 0; i < arrall.length; i++) {
                arr.push([arrall[i], 0])
                arr1.push([arrall[i], 0])
                arr2.push([arrall[i], 0])
            }

            for (let i = 0; i < set1sdf.length; i++) {
                if (set1sdf[i]._id.status === "SUCCESS") {
                    for (let j = 0; j < arr.length; j++) {
                        if (arr[j][0] === set1sdf[i]._id.merchant_name) {
                            arr[j][1] = arr[j][1] + set1sdf[i].number
                            arr2[j][1] = arr2[j][1] + set1sdf[i].total

                        }
                    }
                } else if (set1sdf[i]._id.status === "FAILED") {
                    for (let j = 0; j < arr1.length; j++) {
                        if (arr1[j][0] === set1sdf[i]._id.merchant_name) {
                            arr1[j][1] = arr1[j][1] + set1sdf[i].number
                        }
                    }
                }
            }

            let arr3 = [["From", "To", "Weight"]]
            for (let i = 0; i < arrall.length; i++) {
                for (let j = 0; j < arrapp.length; j++) {
                    var appname = ""
                    if (arrapp[j] === "http://www.paytmbank.com") {
                        appname = "paytm"
                    } else if (arrapp[j] === "https://www.tez.page.link") {
                        appname = "gpay"
                    } else if (arrapp[j] === "http://www.npci.org.in/") {
                        appname = "bhim"
                    } else if (arrapp[j] === "hthttps://axisbank.com") {
                        appname = "axis"
                    } else if (arrapp[j] === "http://www.paytmbank.com") {
                        appname = "paytmBank"
                    } else if (arrapp[j] === "https://upi.hdfcbank.com") {
                        appname = "hdfc"
                    } else if (arrapp[j] === "https://axisbank.com") {
                        appname = "axis"
                    } else if (arrapp[j] === "https://www.sbi.co.in") {
                        appname = "sbi"
                    } else if (arrapp[j] === "https://www.canarabank.in/") {
                        appname = "canara"
                    } else if (arrapp[j] === "https://www.amazon.in/") {
                        appname = "amazon"
                    } else if (arrapp[j] === "https://www.idfcbank.in") {
                        appname = "idfc"
                    } else if (arrapp[j] === "https://epifi.com") {
                        appname = "federalBank"
                    } else {
                        appname = "other"
                    }
                    arr3.push([appname, arrall[i], 0])
                }
            }

            for (let i = 0; i < arrall.length; i++) {
                for (let j = 0; j < arrerr.length; j++) {
                    arr3.push([arrall[i], arrerr[j], 0])
                }
            }
            for (let i = 0; i < arrerr.length; i++) {
                for (let j = 0; j < arrapp.length; j++) {
                    arr3.push([arrerr[i], arrapp[j], 0])
                }
            }


            for (let i = 0; i < arr3.length; i++) {
                for (let k = 0; k < set2sdf.length; k++) {
                    var appname = ""
                    if (set2sdf[k]._id.ref_url === "http://www.paytmbank.com") {
                        appname = "paytm"
                    } else if (set2sdf[k]._id.ref_url === "https://www.tez.page.link") {
                        appname = "gpay"
                    } else if (set2sdf[k]._id.ref_url === "http://www.npci.org.in/") {
                        appname = "bhim"
                    } else if (set2sdf[k]._id.ref_url === "hthttps://axisbank.com") {
                        appname = "axis"
                    } else if (set2sdf[k]._id.ref_url === "http://www.paytmbank.com") {
                        appname = "paytmBank"
                    } else if (set2sdf[k]._id.ref_url === "https://upi.hdfcbank.com") {
                        appname = "hdfc"
                    } else if (set2sdf[k]._id.ref_url === "https://axisbank.com") {
                        appname = "axis"
                    } else if (set2sdf[k]._id.ref_url === "https://www.sbi.co.in") {
                        appname = "sbi"
                    } else if (set2sdf[k]._id.ref_url === "https://www.canarabank.in/") {
                        appname = "canara"
                    } else if (set2sdf[k]._id.ref_url === "https://www.amazon.in/") {
                        appname = "amazon"
                    } else if (set2sdf[k]._id.ref_url === "https://www.idfcbank.in") {
                        appname = "idfc"
                    } else if (set2sdf[k]._id.ref_url === "https://epifi.com") {
                        appname = "federalBank"
                    } else {
                        appname = "other"
                    }
                    if (arr3[i][0] === appname && arr3[i][1] === set2sdf[k]._id.merchant_name) {
                        arr3[i][2] = arr3[i][2] + set2sdf[k].number
                    }
                }
            }
            for (let i = 0; i < arr3.length; i++) {
                for (let k = 0; k < set2sdf.length; k++) {
                    if (arr3[i][0] === set2sdf[k]._id.merchant_name && arr3[i][1] === set2sdf[k]._id.npci_error_code) {
                        arr3[i][2] = arr3[i][2] + set2sdf[k].number
                    }
                }
            }
            for (let i = 0; i < arr3.length; i++) {
                for (let k = 0; k < set2sdf.length; k++) {
                    if (arr3[i][0] === set2sdf[k]._id.npci_error_code && arr3[i][1] === set2sdf[k]._id.ref_url) {
                        arr3[i][2] = arr3[i][2] + set2sdf[k].number
                    }
                }
            }


            setTreeData(arr3)
            setsData(arr)
            setfData(arr1)
            setVolumeData(arr2)
            console.log(arr3)
        } catch (error) {
            console.log(error)
        }
    }


    const options = {
        // pieSliceText: "none",
        title: "SUCCESS/FAILURE Number",
        backgroundColor: { fill: '' },
        titleTextStyle: { color: 'white' },
        legend: { textStyle: { color: 'white' } }
    };






    const optionss = {
        title: "Merchant Volume",
        backgroundColor: { fill: '' },
        titleTextStyle: { color: 'white' },
        legend: { textStyle: { color: 'white' } }
    };


    var soptions = {
        legend: { textStyle: { color: 'white' } },
        sankey: {
            node: {
                // colors: "white",
                // width: 5,
                nodePadding: 20
            },
            link: {
                colorMode: 'gradient',
            }
        }
    };

    const get_analyze = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_analyze`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user')
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    arrange_data(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }





    useEffect(() => {
        // props.check_cookie()
        get_analyze()
    }, [])



    return (
        <>
            <TopbarAdmin handleDash={props.handleDash} />
            <div className="page_wrapper mt15">
                <div className="dashboard_wrapper">

                    <div className="line_chart_wrapper full-width">
                        <Chart
                            chartType="Sankey"
                            width="100%"
                            height="500px"
                            data={getTreeData}
                            options={soptions}
                        />


                    </div>
                    <div className="flex mt15 flex-gap">
                        <div className="multi_data_chart_wrapper text-center">
                            <div className="full-width flex flex-center justify-center">

                                <Chart
                                    chartType="PieChart"
                                    width="100%"
                                    height="400px"
                                    diffdata={{ old: getfData, new: getsData }}
                                    options={options}
                                />

                            </div>
                        </div>
                        <div className="donut_chart_wrapper text-center">
                            <div className="full-width flex flex-center justify-center">


                                <Chart
                                    chartType="PieChart"
                                    data={getVolumeData}
                                    options={optionss}
                                    width={"100%"}
                                    height={"400px"}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



export default Analyzer;




