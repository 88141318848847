import React from 'react';
import './Loader.css'


const Loader = () => {
    return (
       <>
            <div className="flex full-width justify-center flex-center loader_wrapper">
            <div className="loader"></div>
            <p className='ml20'>Logging you in, please wait</p>
            </div>

       </>
    )
}

export default Loader;