import TopbarMerchant from "../common/topbar"
import MultiLineChart from "../extra/chart"
import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import '../pages/Css/Dashboard.css';
import '../../Common/Common.css';
import { MdCurrencyRupee } from "react-icons/md";
import { FaThumbsUp } from "react-icons/fa6";
import { BiMessageError } from "react-icons/bi";
import { MdOutlinePendingActions } from "react-icons/md";

import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
} from 'chart.js';
import { PolarArea } from 'react-chartjs-2';
import { Chart } from "react-google-charts";


const { default: SidebarMerchant } = require("../common/sidebar")




const DashboardMerchant = (props) => {
    const dd = new Date();
    const [getTransactionlist, setTransactionlist] = useState([{}])
    const [getDashboardFilter, setDashboardFilter] = useState({ created_time: { $gte: (dd.getTime() - ((dd.getTime() + 19800000) % 86400000)), $lte: (dd.getTime() - ((dd.getTime() + 19800000) % 86400000)) + 86399999 } })
    const [getDashboardData, setDashboardData] = useState({})
    const [getSuccessCount, setSuccessCount] = useState()
    const [getPendingCount, setPendingCount] = useState()
    const [getFailedCount, setFailedCount] = useState()
    const [getSuccessAmount, setSuccessAmount] = useState()
    const [getPendingAmount, setPendingAmount] = useState()
    const [getFailedAmount, setFailedAmount] = useState()
    const [getChartTick, setChartTick] = useState([])
    const [getChartData, setChartData] = useState([])


    const cookie = new Cookies()
    const get_transaction_list = async () => {
        try {
            props.setLoading("loading")
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/get_transaction_list`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('merchant'),
                    count: 10
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setTransactionlist(dataset.data.data)
                    props.setLoading("success")
                } else {
                    props.setLoading("fail")
                }
            } else {
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
        }
    }
    ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

    const data = {
        labels: [
            'Pending',
            'Success',
            'Failed',
        ],
        datasets: [
            {
                label: 'Transaction',
                data: [getPendingCount, getSuccessCount, getFailedCount],
                backgroundColor: [
                    '#ff933c',
                    '#1fc475',
                    '#f05c80',
                ],
                borderWidth: 0,
            },
        ],
    };

    function CApp() {
        return <PolarArea data={data} />;
    }


    var LineData = [["Date", "Success", "Failed", "Pending"]];

    const update_all_values = () => {
        setSuccessCount(0)
        setSuccessAmount(0)
        setPendingCount(0)
        setPendingAmount(0)
        setFailedCount(0)
        setFailedAmount(0)
        for (let i = 0; i < getDashboardData.length; i++) {
            if (getDashboardData[i]._id === "SUCCESS") {
                setSuccessCount(getDashboardData[i].number)
                setSuccessAmount(getDashboardData[i].total)
            }
            if (getDashboardData[i]._id === "PENDING") {
                setPendingCount(getDashboardData[i].number)
                setPendingAmount(getDashboardData[i].total)
            }
            if (getDashboardData[i]._id === "FAILED") {
                setFailedCount(getDashboardData[i].number)
                setFailedAmount(getDashboardData[i].total)
            }
        }
        let hi = []
        for (let i = 0; i < getChartTick.length; i++) {
            let hello = [`${new Date(getChartTick[i].time).toISOString().slice(0, 10)}`, 0, 0, 0]
            for (let j = 0; j < getChartTick[i].chart_tick.length; j++) {
                const state = getChartTick[i].chart_tick[j]._id
                const total = getChartTick[i].chart_tick[j].total
                if (state === "SUCCESS") {
                    hello[1] = (total / 100)
                }
                if (state === "PENDING") {
                    hello[3] = (total / 100)
                }
                if (state === "FAILED") {
                    hello[2] = (total / 100)
                }
            }
            LineData[i + 1] = hello
            console.log(hello, i, "well")
        }
        setChartData(LineData)
        props.setLoading("success")
        console.log(LineData, "findal data")
    }


    // LineData = [
    //     ['Date', 'Success', 'Failed', 'Pending'],
    //     ['2023-10-09', 2300, 100, 0], 
    //     ['2023-10-11', 2300, 100, 800], 
    //     ['2023-10-14', 0, 0, 800]
    // ];


    const LineChartOptions = {
        backgroundColor: { fill: '#1c1751' },
        hAxis: {
            textStyle: { color: 'white' },
            baselineColor: 'transparent',
            titleTextStyle: { color: 'white' },
            title: 'Date',
            gridlines: {
                color: 'transparent'
            }
        },
        vAxis: {
            textStyle: { color: 'white' },

            baselineColor: 'transparent',
            titleTextStyle: { color: 'white' },
            title: 'INR',
            gridlines: {
                color: 'transparent'
            }
        },
        series: {
            0: { curveType: 'function', color: '#8760f9' },
            1: { curveType: 'function', color: '#1cde7b' },
            2: { curveType: 'function', color: '#fb9227' }
        },
        legend: { position: 'bottom', textStyle: { color: 'white' } },
    }

    var data2 = [
        ["Task", "Hours per Day"],
        ["Success", parseFloat((getSuccessAmount / 100).toFixed(2))],
        ["Failure", parseFloat((getFailedAmount / 100).toFixed(2))],
        ["Pending", parseFloat((getPendingAmount / 100).toFixed(2))],
    ];

    const options = {
        colors: ['#1dd07c', '#f05c80', '#825de9'],
        backgroundColor: { fill: '#1c1751' },
        titleTextStyle: { color: 'white' },
        pieHole: 0.4,
        is3D: false,
        legend: { position: 'top', textStyle: { color: 'white' } }
    };

    function Donut() {
        return (
            <Chart
                chartType="PieChart"
                width="100%"
                height="400px"
                data={data2}
                options={options}
            />
        );
    }


    const blururn = async () => {
        try {
            props.setLoading("loading")
            const dd = new Date()
            const temp = {}
            try {
                var myDate1 = document.getElementById("starttimedash").value; myDate1 = myDate1.split("-"); console.log(myDate1); var newDate1 = new Date(myDate1[0], myDate1[1] - 1, myDate1[2]);
                var myDate2 = document.getElementById("endtimedash").value; myDate2 = myDate2.split("-"); console.log(myDate2); var newDate2 = new Date(myDate2[0], myDate2[1] - 1, myDate2[2]);
                console.log(myDate1, myDate2, "datedate")
                const tempp = {
                    $gte: newDate1.getTime(),
                    $lte: newDate2.getTime() + 86399999
                }
                temp.created_time = tempp
            } catch (error) {
                console.log(error)
                const tempp = {
                    $gte: dd.getTime() - (dd.getTime() % 86400000) - 19800000,
                    $lte: dd.getTime() - (dd.getTime() % 86400000) + 19800000 + 86400000
                }
                temp.created_time = tempp
            }
            console.log(temp)
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/dashboard_value`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('merchant'),
                    filter: temp
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    console.log(dataset.data, "incoming data")
                    setDashboardData(dataset.data.data)
                    setChartTick(dataset.data.chart)
                    update_all_values()
                } else {
                    props.setLoading("fail")
                }
            } else {
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
        }
    }


    const dashboard_data = async () => {
        try {
            // const ffilter = getDashboardFilter
            // const clearfilter = Object.keys(getDashboardFilter)
            // for (let i = 0; i < clearfilter.length; i++) {
            //     const ccfilter = Object.keys(getDashboardFilter[clearfilter[i]])
            //     for (let j = 0; j < ccfilter.length; j++) {
            //         if (getDashboardFilter[clearfilter[i]][ccfilter[j]] === 0 || getDashboardFilter[clearfilter[i]][ccfilter[j]] === "")
            //             delete ffilter[clearfilter[i]]
            //     }
            // }
            // console.log(ffilter)
            props.setLoading("loading")
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/dashboard_value`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('merchant'),
                    filter: getDashboardFilter
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    console.log(dataset.data, "incoming data")
                    setDashboardData(dataset.data.data)
                    setChartTick(dataset.data.chart)
                    update_all_values()
                } else {
                    props.setLoading("fail")
                }
            } else {
                props.setLoading("fail")

            }
        } catch (error) {
            console.log(error)
        }
    }




    useEffect(() => {
        // props.check_cookie()
        get_transaction_list()
        dashboard_data()
    }, [])


    const [count, setCount] = useState(0);

    useEffect(() => {
        update_all_values()
    }, [getChartTick])



    const PopupView = () => {
        if (props.getDashboard) {
            return (
                <>
                    <div className="create_popup_background" onClick={(e) => { props.setDashboard(false); }}>
                    </div>
                    <div className="create_popup_container">
                        <div className="popup_title flex flex-center space-between">
                            <h2>Filter Data</h2>
                            <p onClick={(e) => { props.setDashboard(false); }}>Clear</p>
                        </div>
                        <table className="create_table mt20">
                            <tr>
                                <td><label>From: (required)</label></td>
                                <td><input name="created_time" id="starttimedash" type="Date" /></td>
                            </tr>
                            <tr>
                                <td><label>To: (required)</label></td>
                                <td><input name="created_time" id="endtimedash" type="Date" /></td>
                            </tr>
                        </table>



                        <div className="flex justify-center btn_container">
                            <button onClick={(e) => { blururn() }}>Apply Filter</button>
                        </div>
                    </div>
                </>
            )
        }
    }




    return (
        <>
            <TopbarMerchant setDashboard={props.setDashboard} />

            <div className="page_wrapper">
                <div className="dashboard_wrapper">
                    <PopupView />
                    <div className="dashboard_top flex flex-gap mt15">
                        <div className="card_item full-width flex">
                            <div className="leftpart full-width">
                                <span className="card_icon_container p12 icon1 flex flex-center justify-center">
                                    <MdCurrencyRupee className="dashboard_icon" />
                                </span>
                                <p className="mt10">Data of {parseInt((getDashboardFilter.created_time.$lte - getDashboardFilter.created_time.$gte) / 86400000)} Days</p>
                            </div>
                            <div className="rightpart">
                                <p><span>₹</span>{((getSuccessAmount + getPendingAmount + getFailedAmount) / 100).toLocaleString("en-IN")}</p>
                                <span>{(getSuccessCount + getPendingCount + getFailedCount)}</span>
                            </div>
                        </div>
                        <div className="card_item full-width p20 flex">
                            <div className="leftpart full-width">
                                <span className="card_icon_container p12 icon2 flex flex-center justify-center">
                                    <FaThumbsUp className="dashboard_icon" />
                                </span>
                                <p className="mt10">Data of {parseInt((getDashboardFilter.created_time.$lte - getDashboardFilter.created_time.$gte) / 86400000)} Days</p>
                            </div>
                            <div className="rightpart">
                                <p><span>₹</span>{(getSuccessAmount / 100).toLocaleString("en-IN")}</p>
                                <span>{getSuccessCount}</span>
                            </div>
                            {/* success */}
                        </div>
                        <div className="card_item full-width p20 flex">
                            <div className="leftpart full-width">
                                <span className="card_icon_container p12 icon3 flex flex-center justify-center">
                                    <BiMessageError className="dashboard_icon" />
                                </span>
                                <p className="mt10">Data of {parseInt((getDashboardFilter.created_time.$lte - getDashboardFilter.created_time.$gte) / 86400000)} Days</p>
                            </div>
                            <div className="rightpart">
                                <p><span>₹</span>{(getFailedAmount / 100).toLocaleString("en-IN")}</p>
                                <span>{getFailedCount}</span>
                            </div>
                        </div>
                        <div className="card_item full-width p20 flex">
                            <div className="leftpart full-width">
                                <span class="card_icon_container p12 icon4 flex flex-center justify-center">
                                    <MdOutlinePendingActions className="dashboard_icon" />
                                </span>
                                <p className="mt10">Data of {parseInt((getDashboardFilter.created_time.$lte - getDashboardFilter.created_time.$gte) / 86400000)} Days</p>
                            </div>
                            <div className="rightpart">
                                <p><span>₹</span>{(getPendingAmount / 100).toLocaleString("en-IN")}</p>
                                <span>{getPendingCount}</span>
                            </div>
                        </div>
                    </div>
                    <div className="line_chart_wrapper full-width mt15">

                        <h3>Transaction Statics</h3>
                        <MultiLineChart LineData={getChartData} LineChartOptions={LineChartOptions} style={{ maxWidth: '100%' }} />


                        {/* <div className="tran_table">
                                <div>
                                    <h3>Recent Orders:</h3>
                                    <table>
                                        <tr>
                                            <th>Transaction ID</th>
                                            <th>Order ID</th>
                                            <th>Amount</th>
                                            <th className="specialCenter">Status</th>
                                            <th className="specialCenter">Updated Time</th>
                                        </tr>
                                        {
                                            getTransactionlist.map((e, index) => {
                                                let txn_status;
                                                if (e.status === "PENDING") {
                                                    txn_status = <div className="pending" name={e.order_id}>
                                                        <p>Pending</p>
                                                    </div>
                                                }
                                                if (e.status === "SUCCESS") {
                                                    txn_status = <div className="success">
                                                        <p>Success</p>
                                                    </div>
                                                }
                                                if (e.status === "FAILED") {
                                                    txn_status = <div className="failed">
                                                        <p>Failed</p>
                                                    </div>
                                                }
                                                const create = new Date(e.created_time).toLocaleString()
                                                const update = new Date(e.updated_time).toLocaleString()
                                                return (
                                                    <tr>
                                                        <td>{e.txn_id}</td>
                                                        <td>{e.order_id}</td>
                                                        <td>Rs {(e.amount / 100).toFixed(2)}</td>
                                                        <td>
                                                            {txn_status}
                                                        </td>
                                                        <td>{update}</td>
                                                    </tr>
                                                )
                                            })
                                        }

                                    </table>
                                </div>
                            </div> */}


                    </div>

                    <div className="flex mt15 flex-gap">
                        <div className="multi_data_chart_wrapper text-center">
                            {/* <div className="displayBlockup">
                                    <div className="color1"><div> </div><p> Pending</p></div>
                                    <div className="color2"><div> </div><p> Success</p></div>
                                    <div className="color3"><div> </div><p> Failure</p></div>
                                </div> */}

                            <h3 className="mb20">Transaction Status</h3>
                            <div className="full-width flex flex-center justify-center">
                                <CApp />
                            </div>
                        </div>
                        <div className="donut_chart_wrapper text-center">
                            <h3>Amount Status</h3>
                            <div className="full-width flex flex-center justify-center">
                                <Donut />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashboardMerchant;