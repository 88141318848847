import Cookies from 'universal-cookie'
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";


const { default: SidebarAdmin } = require("../common/sidebar")
const { default: TopbarAdmin } = require("../common/topbar")



const Logout = (props) => {

    const cookies = new Cookies()
    const navigate = useNavigate()


    const logout = async () => {
        try {
            cookies.remove("user");
            navigate('/admin')
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        logout()
    }, [])

    return (
        <>
            <SidebarAdmin />
            <TopbarAdmin handleDash={props.handleDash} />
        </>
    )
}


export default Logout