import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import '../../Common/Common.css';
import SidebarAdmin from "../common/sidebar";
import TopbarAdmin from "../common/topbar";



const Unclaimed = (props) => {
    const [getMerchantList, setMerchantList] = useState([{}])
    const [getSelectedMerchant, setSelectedMerchant] = useState("")
    const [getAcquirerlist, setAcquirerlist] = useState([{}])
    const [getTransactionlist, setTransactionlist] = useState([{}])
    const [getRow, setRow] = useState(10)
    const [getFilter, setFilter] = useState({})

    const cookie = new Cookies()



    const search_logs = async () => {
        try {
            setTransactionlist([])
            document.getElementById("loadingbarhere").style.display = "grid"
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/search_utr_unclaimed`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    utr: document.getElementsByName("rrn")[0].value
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setTransactionlist(dataset.data.data)
                    document.getElementById("loadingbarhere").style.display = "none"
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const get_utr_logs = async () => {
        try {
            setTransactionlist([])
            document.getElementById("loadingbarhere").style.display = "grid"
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/utr_log`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    count: 100,
                    filter: JSON.stringify({
                        merchant_id: "empty"
                    })
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setTransactionlist(dataset.data.data)
                    document.getElementById("loadingbarhere").style.display = "none"
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    const get_merchant_list = async () => {
        try {
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/get_merchant`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user')
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setMerchantList(dataset.data.data)
                }
            }
        } catch (error) {
            console.log(error)
        }
    }

    const claim_logs = async (utr) => {
        try {
            props.setLoading("loading")
            const resp = await fetch(`${process.env.REACT_APP_URL}/admin/utr_transfer`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('user'),
                    utr: utr,
                    merchant: getSelectedMerchant
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    get_utr_logs()
                    props.setLoading("success")
                } else {
                    props.setLoading("fail")
                }
            } else {
                props.setLoading("fail")
            }
        } catch (error) {
            console.log(error)
        }
    }



    useEffect(() => {
        // props.check_cookie()
        get_utr_logs()
        get_merchant_list()
    }, [])



    return (
        <>
            <TopbarAdmin handleDash={props.handleDash} />
            <div className="page_wrapper mt15">
                <div className="content_wrapper">
                    <div className="flex flex-gap-extra-extended options_table_container justify-center">
                        <table className="options_table">
                            <tr>
                                <td>
                                    <label>Claim for Merchant: </label>
                                </td>
                                <td>
                                    <select name="merchant_id" onChange={(e) => { setSelectedMerchant(e.target.value) }} className="ml30">
                                        <option>Select Merchant</option>
                                        {
                                            getMerchantList.map((e, index) => {
                                                return (
                                                    <option value={e.id}>{e.name}</option>
                                                )
                                            })
                                        }
                                    </select>
                                </td>
                            </tr>
                        </table>
                        <table className="options_table">
                            <tr>
                                <td>
                                    <label>RRN:</label>
                                </td>
                                <td>
                                    <input name="rrn" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter RRN Number" />
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div className="flex flex-center justify-center full-width mt30">
                        <button onClick={(e) => { search_logs() }}>Search Logs</button>
                    </div>
                </div>
                <div className="content_wrapper mt15">
                    <div className="tran_table">
                        {/* <div className="rowscount">
                        <select onBlur={(e) => { get_transaction_list() }} onChange={(e) => { setRow(e.target.value); }}>
                            <option value={10}>10 Rows</option>
                            <option value={20}>20 Rows</option>
                            <option value={50}>50 Rows</option>
                            <option value={100}>100 Rows</option>
                        </select>
                    </div> */}


                        <table>
                            <tr>
                                {/* <th>Merchant</th> */}
                                {/* <th>Acquirer</th> */}
                                {/* <th>Utr</th> */}
                                <th>Transaction ID</th>
                                <th>Order ID</th>
                                <th>Amount</th>
                                <th>Status</th>
                                <th>Time & Date</th>

                            </tr>
                            {
                                getTransactionlist.map((e, index) => {
                                    let txn_status;
                                    if (e.status === "PENDING") {
                                        txn_status = <div className="pending" name={e.order_id}>
                                            <p>Pending</p>
                                        </div>
                                    }
                                    if (e.status === "SUCCESS") {
                                        txn_status = <div className="success">
                                            <p>Success</p>
                                        </div>
                                    }
                                    if (e.status === "FAILED") {
                                        txn_status = <div className="failed">
                                            <p>Failed</p>
                                        </div>
                                    }
                                    const create = new Date(e.created_time).toLocaleString()
                                    const update = new Date(e.updated_time).toLocaleString()
                                    return (
                                        <tr>
                                            {/* <td>test</td> */}
                                            {/* <td>test</td> */}
                                            {/* <td>testutr</td> */}
                                            <td>{e.txn_id}</td>
                                            <td>{e.order_id}</td>
                                            <td>₹ {(e.amount / 100).toLocaleString("en-IN")}</td>
                                            <td>
                                                {txn_status}
                                            </td>
                                            <td>{create}</td>
                                            <td>
                                                <button name={e.rrn} onClick={(e) => { claim_logs(e.target.name) }} className="table_btn">Claim</button>
                                            </td>

                                        </tr>
                                    )
                                })
                            }

                        </table>
                        <div id="loadingbarhere" class="loader" style={{ width: "100%", display: 'none' }}></div>
                    </div>
                </div>
            </div>

        </>
    )
}



export default Unclaimed;