import Cookies from "universal-cookie";
import { useEffect, useState } from "react";
import '../../Common/Common.css';
import SidebarMerchant from "../common/sidebar";
import TopbarMerchant from "../common/topbar";



const TransactionMerchant = (props) => {
    const [getTransactionlist, setTransactionlist] = useState([{}])
    const [getRow, setRow] = useState(10)
    const [getFilter, setFilter] = useState({})

    const cookie = new Cookies()


    const search_transaction = async () => {
        try {
            const ffilter = getFilter
            const clearfilter = Object.keys(getFilter)
            for (let i = 0; i < clearfilter.length; i++) {
                const ccfilter = Object.keys(getFilter[clearfilter[i]])
                for (let j = 0; j < ccfilter.length; j++) {
                    if (getFilter[clearfilter[i]][ccfilter[j]] === 0 || getFilter[clearfilter[i]][ccfilter[j]] === "")
                        delete ffilter[clearfilter[i]]
                }
            }
            setTransactionlist([])
            document.getElementById("loadingbarhere").style.display = "grid"
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/search_transaction`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('merchant'),
                    count: getRow,
                    filter: ffilter
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setTransactionlist(dataset.data.data)
                    document.getElementById("loadingbarhere").style.display = "none"
                }
            }
        } catch (error) {
            console.log(error)
        }
    }




    const get_transaction_list = async () => {
        try {
            setTransactionlist([])
            document.getElementById("loadingbarhere").style.display = "grid"
            const resp = await fetch(`${process.env.REACT_APP_URL}/merchant/get_transaction_list`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json; charset=UTF-8"
                },
                body: JSON.stringify({
                    token: cookie.get('merchant'),
                    count: getRow
                })
            })
            if (resp.status === 200) {
                const dataset = await resp.json()
                if (dataset.status === true) {
                    setTransactionlist(dataset.data.data)
                    document.getElementById("loadingbarhere").style.display = "none"
                }
            }
        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        // props.check_cookie()
        get_transaction_list()
    }, [])



    return (
        <>
            <TopbarMerchant handleDash={props.handleDash} />
            <div className="page_wrapper mt15">
                <div className="content_wrapper">

                    <div className="flex flex-gap-extended options_table_container">
                        <table className="full-width options_table">
                            <tr>
                                <td>
                                    <label>Amount:</label>
                                </td>
                                <td>
                                    <input name="amount" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: (e.target.value * 100) } }) }} placeholder="Enter Amount" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Order ID:</label>
                                </td>
                                <td>
                                    <input name="order_id" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Order ID" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>From:</label>
                                </td>
                                <td>
                                    <input name="created_time" onChange={(e) => { var myDate = e.target.value; myDate = myDate.split("-"); console.log(myDate); var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]); setFilter({ ...getFilter, [e.target.name]: { ...getFilter[e.target.name], $gte: newDate.getTime() } }) }} type="Date" />
                                </td>
                            </tr>
                            <tr>
                                <td><label>To:</label></td>
                                <td>
                                    <input name="created_time" onChange={(e) => { var myDate = e.target.value; myDate = myDate.split("-"); console.log(myDate); var newDate = new Date(myDate[0], myDate[1] - 1, myDate[2]); setFilter({ ...getFilter, [e.target.name]: { ...getFilter[e.target.name], $lte: newDate.getTime() + 86399999 } }) }} type="Date" />
                                </td>

                            </tr>
                        </table>
                        <table className="full-width options_table">
                            <tr>
                                <td>
                                    <label>RRN:</label>
                                </td>
                                <td>
                                    <input name="rrn" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter RRN Number" />
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <label>VPA / UPI ID:</label>
                                </td>
                                <td>
                                    <input name="payer_vpa" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter UPI ID" />
                                </td>
                            </tr>
                            <tr>
                                <td><label>Mobile Number</label></td>
                                <td><input name="payer_mobile" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }} placeholder="Enter Mobile Number" /></td>
                            </tr>
                            <tr>
                                <td>
                                    <label>Status</label>
                                </td>
                                <td>
                                    <select name="status" onChange={(e) => { setFilter({ ...getFilter, [e.target.name]: { $eq: e.target.value } }) }}>
                                        <option value="SUCCESS">Success</option>
                                        <option value="FAILED">Failure</option>
                                        <option value="PENDING">Pending</option>
                                    </select>
                                </td>
                            </tr>
                        </table>
                    </div>

                    <div className="flex flex-center justify-center full-width mt30">
                        <button onClick={(e) => { search_transaction() }}>Search</button>
                    </div>
                </div>
                <div className="content_wrapper mt15">
                    <div className="tran_table">
                        <div className="rowscount mb10 flex justify-end full-width">
                            <select onBlur={(e) => { get_transaction_list() }} onChange={(e) => { setRow(e.target.value); }}>
                                <option value={10}>10 Rows</option>
                                <option value={20}>20 Rows</option>
                                <option value={50}>50 Rows</option>
                                <option value={100}>100 Rows</option>
                            </select>
                        </div>
                        <div>

                            <table>
                                <tr>
                                    <th>Order ID</th>
                                    <th>Transaction ID</th>
                                    <th>Amount</th>
                                    <th>Status</th>
                                    <th>Date & Time</th>

                                </tr>
                                {
                                    getTransactionlist.map((e, index) => {
                                        let txn_status;
                                        if (e.status === "PENDING") {
                                            txn_status = <div className="pending" name={e.order_id}>
                                                <p>Pending</p>
                                            </div>
                                        }
                                        if (e.status === "SUCCESS") {
                                            txn_status = <div className="success">
                                                <p>Success</p>
                                            </div>
                                        }
                                        if (e.status === "FAILED") {
                                            txn_status = <div className="failed">
                                                <p>Failed</p>
                                            </div>
                                        }
                                        const create = new Date(e.created_time).toLocaleString()
                                        const update = new Date(e.updated_time).toLocaleString()
                                        return (
                                            <tr>
                                                <td>{e.order_id}</td>
                                                <td>{e.txn_id}</td>
                                                <td>₹ {(e.amount / 100).toLocaleString("en-IN")}</td>
                                                <td>
                                                    {txn_status}
                                                </td>
                                                <td style={{ fontSize: "12px" }}>{create} <br /> {update}</td>

                                            </tr>
                                        )
                                    })
                                }

                            </table>
                            <div id="loadingbarhere" class="loader" style={{ width: "100%", display: 'none' }}></div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}



export default TransactionMerchant;